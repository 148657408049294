button.dropdown-item.ng-star-inserted {
  border: none;
  padding: 1rem;
  margin-left: 0 !important;
  cursor: pointer;
  color: var(--m3-sys-on-background);
  background-color: var(--color-surface-5);

  &:hover {
    background: var(--m3-sys-secondary-container);
  }

  &.active {
    background: var(--m3-sys-secondary-container);
  }
}

.sales-order__head-field .dropdown-menu {
  display: flex;
  flex-direction: column;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--m3-sys-secondary-container);
}

.dark-theme {
  .dropdown-menu {
    --bg: var(--m3-black);
    --fg: var(--m3-sys-secondary-container);
    background: linear-gradient(0deg, var(--fg), var(--fg)), var(--bg) !important;
  }

}

.light-theme {
  .dropdown-menu {
    --bg: var(--m3-white);
    --fg: var(--m3-sys-secondary-container);
    background: linear-gradient(0deg, var(--fg), var(--fg)), var(--bg) !important;
  }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  background-color: var(--m3-sys-background) !important;
  padding: 0 !important;

  .mat-mdc-option {
    font-size: var(--font-size-base);
    background: var(--m3-state-layers-surface-tint-opacity-08) !important;
    color: var(--m3-sys-on-surface) !important;
  }

  .mat-mdc-option:hover:not(.mat-mdc-option-disabled),
  .mat-mdc-option:focus:not(.mat-mdc-option-disabled) {
    background: var(--m3-sys-secondary-container) !important;
  }
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled) {
  color: var(--m3-sys-primary) !important;
}

.dropdown-menu--dialog {
  .dropdown-menu {
    width: 100% !important;

  }
}

.mat-option.mat-active {
  background: var(--m3-sys-secondary-container) !important;
}


.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: var(--m3-sys-secondary-container) !important;
}
body {
  .mat-radio-outer-circle {
    border-color: var(--m3-sys-on-surface-variant);
  }

  .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
  .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
    border-color: var(--m3-sys-on-surface);
  }

  .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: var(--m3-sys-on-surface);
  }

  .mat-radio-disabled {
    opacity: .38;
  }
}

:root {
  --nav-link-padding: 4px 5px;
  --nav-link-space: 0;
  --nav-link-size: var(--typo-label-large);
  --nav-link-weight: var(--font-medium);
  --nav-link-radius: var(--border-radius-rounded);
  --nav-link-icon-space: 0.75rem;

  --nav-link-bg: transparent;
  --nav-link-icon-fill: var(--m3-sys-on-surface);

}

#{$dark-theme} {
  --nav-link-icon-fill: var(--m3-sys-on-surface);
}

.side-nav {
  height: var(--bottom-bar-height);
  display: flex;
  align-items: center;
  background-color: var(--m3-sys-background);
  padding: 0 var(--space-s);

  @include media-breakpoint-down(sm) {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include media-breakpoint-up(sm) {
    align-items: initial;
    background-color: transparent;
    height: calc(100vh - var(--header-height));
    padding-top: var(--space-none);
    padding-bottom: var(--space-m);
    padding-inline-start: var(--space-m);
    padding-inline-end: var(--space-l);
    flex-direction: column;
  }
}

.side-nav__item {
  @include media-breakpoint-up(sm) {
    position: relative;

    +.side-nav__item {
      margin-top: var(--space-s);
    }
  }
}

.side-nav__link {
  @extend .typo-label-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  padding: var(--nav-link-padding);
  border: 0;
  text-decoration: none;
  border-radius: var(--nav-link-radius);
  background-color: var(--nav-link-bg);
  color: var(--m3-sys-on-surface-variant);
  margin: var(--nav-link-space);
  position: relative;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &--starred-icon {
    color: var(--m3-sys-secondary) !important;
    transition: all .3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--active {}
  }

  .mat-ripple-element {
    background-color: var(--m3-sys-primary-container);
    opacity: .4;
    z-index: -1;
  }

  &:hover {
    --nav-link-icon-fill: var(--m3-sys-inverse-on-surface);
    --nav-link-bg: var(--m3-sys-surface-variant);
    color: var(--m3-sys-primary);

    .side-nav__link--starred-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  @include media-breakpoint-up(sm) {
    --nav-link-padding: 2px 1rem;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    height: 2.625rem;
  }

  &--change-theme {
    border: 1px solid var(--m3-sys-surface-variant);
  }

  &--notifications {
    display: flex;
    justify-content: space-between;
  }

}

.side-nav__link--active {
  --nav-link-icon-fill: var(--m3-sys-inverse-on-surface);
  --nav-link-bg: var(--m3-sys-surface) !important;
  color: var(--m3-sys-primary);
}

.side-nav__link--edit {
  background-color: var(--m3-sys-tertiary-container);
  height: 56px;
  border-radius: 16px;

  &:hover {
    --nav-link-icon-fill: transparent;
    --nav-link-bg: transparent;
    color: transparent;
  }

  .mat-ripple-element {
    background-color: var(--m3-sys-tertiary-container);
    opacity: .4;
    z-index: -1;
  }

  .mat-icon {
    color: var(--m3-sys-on-tertiary-container);
  }
}

.side-nav__icon {
  svg {
    // stroke: var(--nav-link-icon-fill);
    transition: all 0.15s ease-in-out;
    fill: var(--m3-sys-on-surface) !important;

  }

  +.side-nav__title {
    @include media-breakpoint-up(sm) {
      margin-inline-start: var(--nav-link-icon-space);
    }
  }
}

.side-nav__sub {
  background: var(--m3-sys-surface);
  color: var(--m3-sys-on-surface);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 80vh;
  padding: var(--space-xxl) var(--space-l) var(--space-m) var(--space-l);
  border-radius: var(--border-radius-rounded) var(--border-radius-rounded) 0 0;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 80px);
  transition-delay: 300ms;
  z-index: 10;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -20px;
    bottom: auto;
    left: calc(100% + var(--space-base));
    width: 322px;
    max-height: 80vh;
    padding: var(--space-none);
    border-radius: var(--border-radius-m);
    transform: translate(10px, 0);

    [dir="rtl"] & {
      left: auto;
      right: calc(100% + var(--space-base));
    }
  }

  .side-nav__link {
    @include media-breakpoint-down(sm) {
      --nav-link-padding: 10px 16px;
      width: 100%;
      justify-content: flex-start;
      flex-direction: row;

    }

    +.side-nav__link {
      margin-top: var(--space-s);
    }
  }

  &:before {
    content: "";
    right: 100%;
    top: 40px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: var(--m3-sys-surface);
    border-width: 10px;
    margin-top: -10px;

    [dir="rtl"] & {
      left: 100%;
      right: unset;
      border-left-color: var(--m3-sys-surface);
      border-right-color: transparent;
    }
  }

  &--above {
    @include media-breakpoint-up(sm) {
      bottom: -20px;
      top: auto;
    }

    &:before {
      top: auto;
      bottom: 40px;
    }
  }

  &--middle {
    @include media-breakpoint-up(sm) {
      bottom: var(--space-base);
      top: calc(var(--header-height) + var(--space-base));
      position: fixed;
      left: calc(var(--nav-width) + var(--space-base));

      [dir="rtl"] & {
        right: calc(var(--nav-width) + var(--space-base));
        left: auto;
      }
    }

    &:before {
      display: none;
    }
  }
}

.side-nav__item--selected {
  position: relative;
  z-index: 10;

  >.side-nav__link {
    --nav-link-icon-fill: var(--m3-sys-inverse-on-surface);
    --nav-link-bg: var(--m3-sys-surface);
    color: var(--m3-sys-primary);
  }

  .side-nav__sub {
    opacity: 1;
    visibility: visible;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    transform: translate(0, 0);
  }
}

.side-nav__back {
  @extend .typo-title-large;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  color: var(--m3-sys-primary);
}

.side-nav--collapsed {
  .side-nav__title {
    font-size: 10px;
    text-align: center;
    padding: 0 .1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    .side-nav__icon {
      margin: auto;
    }
  }

  // @include media-breakpoint-up(sm) {
  //   padding-inline-start: var(--space-s);
  //   padding-inline-end: var(--space-s);
  // }
  // .side-nav__item--selected{
  //   > .side-nav__link{
  //     --nav-link-bg: transparent;
  //   }
  // }
  // .side-nav__item {
  //   > .side-nav__link{
  //     --nav-link-radius: 10px;
  //     --nav-link-padding: 12px 0;
  //     flex-direction: column;
  //   }
  //   .side-nav__title{
  //     display: none;
  //     font-size: 10px;
  //     text-align: center;
  //     margin-top: 0.8em;
  //     padding: 0 .1em;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //     max-width: 100%;
  //   }
  //   .side-nav__icon + .side-nav__title{
  //     margin-inline-start: 0;
  //   }
  // }


  // .side-nav__link--active,
  // .side-nav__link--active:hover{
  //   --fill-color: var(--m3-sys-secondary-container);
  // }
  @include media-breakpoint-down(sm) {
    .side-nav__icon {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -15px;
        right: -15px;
        top: -2px;
        bottom: -2px;
        background: transparent;
        z-index: -1;
        border-radius: var(--border-radius-rounded);
        transition: all .3s ease-in-out;
      }
    }

    .side-nav__link:hover,
    .side-nav__item--selected,
    .side-nav__link--active {
      --fill-color: var(--m3-sys-surface-variant);
      --nav-link-bg: transparent;
      color: var(--m3-sys-on-surface);

      .side-nav__icon {
        color: var(--m3-sys-on-secondary-container);

        &:before {
          background: var(--fill-color);
        }
      }
    }
  }
}

.side-nav__divider {
  margin-top: auto;
  border: 0;
  background: transparent;

  +.side-nav__item {
    margin-top: var(--space-s);
  }
}

.side-nav__sub-container {
  @include media-breakpoint-up(sm) {
    height: 100%;
    overflow: auto;
    display: block;
    padding: var(--space-m);
  }
}

.side-nav__item--more {
  @include media-breakpoint-down(sm) {
    .side-nav__link {
      --nav-link-padding: 10px 16px;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
    }

    .side-nav__icon {
      margin-inline-end: var(--space-base);
    }

    .side-nav__sub {
      position: fixed;
      top: 0;
      box-shadow: none;
      display: none;
      height: 100%;
      overflow: auto;
    }

    &.side-nav__item--selected {
      .side-nav__sub {
        display: block;
      }
    }
  }
}

.side-nav__sub-title {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.4em;
  color: var(--m3-sys-primary);
  @extend %sys-bg;
  @extend .typo-title-medium;
  margin: calc(-1 * var(--space-m)) calc(-1 * var(--space-m)) 0.4em calc(-1 * var(--space-m));
  padding: var(--space-m) var(--space-xl);
  border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
}

.nav-bottom-sheet.mat-bottom-sheet-container {
  max-height: 70vh;
}

.side-nav__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--m3-ref-primary-primary25);
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  &--show {
    visibility: visible;
    opacity: 0.1;
    transition: all .3s ease-in-out;
  }
}

.side-nav--items {
  overflow: hidden;
  overflow-y: scroll;
  height: 85vh;
}

.side-nav__sub--sales {
  @include media-breakpoint-down(xl) {
    top: -74px;

    &::before {
      top: 95px;
    }
  }
}

.divider {
  margin: var(--space-m) 0;
  border: 1px solid var(--m3-sys-outline-variant);
  background: transparent;

  +.side-nav__item {
    margin-top: var(--space-s);
  }
}

body {
  .nav-drawer {
    background-color: var(--color-surface-2);
    height: auto;

    .side-nav__link--active {
      background-color: var(--m3-sys-secondary-container);
      color: var(--m3-sys-on-secondary-container);

      &--transparent {
        background: transparent;
        color: var(--m3-sys-on-surface-variant);
      }

      &--grey-1 {
        background-color: var(--m3-state-layers-surface-opacity-08);
        color: var(--m3-sys-on-surface-variant);
      }

      &--grey-2 {
        background-color: var(--m3-state-layers-surface-opacity-12);
        color: var(--m3-sys-on-surface-variant);
      }

      &--secondary-1 {
        background-color: var(--m3-sys-secondary-container);
        color: var(--m3-sys-on-secondary-container);
      }

      &--secondary-2 {
        background-color: var(--m3-state-secondary-container-opacity-08);
        color: var(--m3-sys-on-secondary-container);
      }

      &--secondary-3 {
        background-color: var(--m3-state-secondary-container-opacity-12);
        color: var(--m3-sys-on-secondary-container);
      }

      &--on-grey-1 {
        background-color: var(--m3-state-layers-on-surface-opacity-08);
        color: var(--m3-sys-on-surface-variant);
      }

      &--on-grey-2 {
        background-color: var(--m3-state-layers-on-surface-opacity-12);
        color: var(--m3-sys-on-surface-variant);
      }
    }

    .side-nav__link {
      height: 3.5rem;

      &--transparent {
        background: transparent;
        color: var(--m3-sys-on-surface-variant);
      }

      &--grey-1 {
        &:hover {
          background-color: var(--m3-state-layers-surface-opacity-08);
          color: var(--m3-sys-on-surface-variant);
        }
      }

      &--grey-2 {
        &:hover {
          background-color: var(--m3-state-layers-surface-opacity-12);
          color: var(--m3-sys-on-surface-variant);
        }
      }

      &--secondary-1 {
        &:hover {
          background-color: var(--m3-sys-secondary-container);
          color: var(--m3-sys-on-secondary-container);
        }
      }

      &--secondary-2 {
        &:hover {
          background-color: var(--m3-state-layers-secondary-container-opacity-08);
          color: var(--m3-sys-on-secondary-container);
        }
      }

      &--secondary-3 {
        &:hover {
          background-color: var(--m3-state-layers-secondary-container-opacity-12);
          color: var(--m3-sys-on-secondary-container);
        }
      }

      &--on-grey-1 {
        &:hover {
          background-color: var(--m3-state-layers-on-surface-opacity-08);
          color: var(--m3-sys-on-surface-variant);
        }
      }

      &--on-grey-2 {
        &:hover {
          background-color: var(--m3-state-layers-on-surface-opacity-12);
          color: var(--m3-sys-on-surface-variant);
        }
      }
    }

    .side-nav__icon+.side-nav__title {
      line-height: 1.5rem;
    }
  }
}

@-webkit-keyframes IndicatorAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes IndicatorAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes IndicatorAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes IndicatorAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.starred-indicator {
  position: absolute;
  inset-inline-start: 4px;
  opacity: 0;
  -webkit-animation: IndicatorAnimation 1s ease-in-out;
  -moz-animation: IndicatorAnimation 1s ease-in-out;
  -o-animation: IndicatorAnimation 1s ease-in-out;
  animation: IndicatorAnimation 1s ease-in-out;
}
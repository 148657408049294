:root {
  @each $size, $value in $sizes {
    --space-#{$size}: #{$value};
  }
}

@each $size, $value in $sizes {
  .margin-#{$size} {
    margin: var(--space-#{$size}) !important;
  }
  .padding-#{$size} {
    padding: var(--space-#{$size}) !important;
  }
  @each $direction, $value in $directions {
    .margin-#{$direction}-#{$size} {
      @if $direction == "x" {
        margin-left: var(--space-#{$size}) !important;
        margin-right: var(--space-#{$size}) !important;
      } @else if $direction == "y" {
        margin-top: var(--space-#{$size}) !important;
        margin-bottom: var(--space-#{$size}) !important;
      } @else {
        margin-#{$value}: var(--space-#{$size}) !important;
      }
    }

    .padding-#{$direction}-#{$size} {
      @if $direction == "x" {
        padding-left: var(--space-#{$size}) !important;
        padding-right: var(--space-#{$size}) !important;
      } @else if $direction == "y" {
        padding-top: var(--space-#{$size}) !important;
        padding-bottom: var(--space-#{$size}) !important;
      } @else {
        padding-#{$value}: var(--space-#{$size}) !important;
      }
    }
  }
}
.margin-inline-start-xl {
  margin-inline-start: var(--space-xl) !important;
}
.margin-inline-start-auto{
  margin-inline-start: auto !important;
}

.margin-inline-end-xl {
  margin-inline-end: var(--space-xl) !important;
}

.margin-inline-start-l {
  margin-inline-start: var(--space-l) !important;
}

.margin-inline-end-l {
  margin-inline-end: var(--space-l) !important;
}

.margin-inline-start-m {
  margin-inline-start: var(--space-m) !important;
}

.margin-inline-end-m {
  margin-inline-end: var(--space-m) !important;
}

.margin-inline-start-base-m {
  margin-inline-start: var(--space-base-m) !important;
}

.margin-inline-end-base-m {
  margin-inline-end: var(--space-base-m) !important;
}

.margin-inline-start-base {
  margin-inline-start: var(--space-base) !important;
}

.margin-inline-end-base {
  margin-inline-end: var(--space-base) !important;
}

.margin-inline-start-s {
  margin-inline-start: var(--space-s) !important;
}

.margin-inline-end-s {
  margin-inline-end: var(--space-s) !important;
}
.margin-inline-start-xs {
  margin-inline-start: var(--space-xs) !important;
}

.margin-inline-end-xs {
  margin-inline-end: var(--space-xs) !important;
}

.auth {
  display: flex;
  flex-direction: column;
  padding: var(--space-xl);
  padding-bottom: var(--space-none);
  height: 100%;

  .auth__card {
    padding: var(--space-xl);
    background: var(--m3-sys-surface);
    max-width: 440px;
    margin: auto;
  }

  .auth__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-l);

    .mat-menu-trigger {
      display: flex;
      align-items: center;

      .text {
        @include breakpoint(isMobile) {
          display: none;
        }
      }
    }
  }

  .mat-mdc-card-title h2 {
    font-size: var(--typo-display-small);
    font-weight: 400;
    color: var(--m3-sys-on-surface);
    margin: 0;
    line-height: normal;
    text-align: center;
  }

  .auth__link {
    text-decoration: none;
    color: var(--m3-sys-primary);
    font-size: var(--typo-body-medium);
  }

}

.auth__footer {
  margin-top: auto;
  font-weight: 500;

  .mat-mdc-icon-button svg {
    height: 16px;
    width: 16px;
  }
}
// .mat-dialog-container {
//   height: auto !important;
// }

.dialog {
  &--md {
    width: 55vw;

    @include media-breakpoint-down(sm) {
      width: 95vw !important;
    }
  }
}

.dialog {
  &--sm {
    width: 30vw;

    @include media-breakpoint-down(sm) {
      width: 95vw !important;
    }
  }
}

.dialog__header {
  color: var(--m3-sys-on-surface);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  border-bottom: 1px solid var(--m3-sys-outline-variant);
  padding-bottom: 10px;

  .typo-title {
    margin: 0;
  }
}

.no_border-bottom {
  border-bottom: unset !important;
}

.dialog__footer {
  border-top: 1px solid var(--m3-sys-outline-variant) !important;

  &--justify {
    justify-content: space-between;
  }
}

.no_border-top {
  border-top: unset !important;
}

.dialog__height {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    height: fit-content;
  }

  mat-dialog-content {
    max-height: 100%;
    padding: 8px 24px !important;
    margin: 0 -24px;
  }

  .dialog__footer {
    margin-top: auto !important;
  }
}

body .mat-dialog-content {
  max-height: 100%;
}

.items__total-costs {
  margin: 20px 0;

  strong {
    @include ltr-prop(margin-left, margin-right, 10px, 0);
  }
}

.stock-items-modal {
  .modal-content {
    .card-button {
      box-shadow: none;
      border: 2px solid #e2e2e2;

      &:hover {
        border-color: $color-primary;
      }
    }
  }
}

// .mat-dialog-content {
//   height: inherit !important;
//   max-height: initial !important;
//   padding-top: 0.2em !important;
// }

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 1;
  background: #00000052;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-content {
  --bg: var(--m3-sys-background);
  --fg: var(--color-surface-1);
  background-color: var(--m3-sys-background);
  display: block;
  padding: 24px;
  border-radius: 4px;
  width: 80%;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  color: var(--m3-sys-on-surface);

  &--sm {
    width: 50%;

    @include media-breakpoint-down(sm) {
      width: 80vw !important;
    }
  }
}

.modal__body {
  max-height: calc(100vh - 18em) !important;
  overflow: initial;
}

.animate {
  animation: zoom 0.2s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.confirm-modal {
  width: 80vw;
  max-width: 400px;
}

.full-screen-modal {
  width: 100vw !important;
  height: 100vh !important;

  .modal__body {
    max-height: calc(100vh - 6.8em) !important;
  }
}

.dialog-full-screen {
  width: 100%;
  height: 100%;
  max-width: none;

  .mat-dialog-content {
    height: 100% !important;
    overflow: hidden;
    padding: var(--space-m) !important;
    height: auto !important;
  }
}

body {
  .mdc-dialog__content {
    flex-grow: unset;
  }

  .mat-mdc-dialog-container {
    box-shadow: none;
    --bg: var(--m3-sys-surface);
    --fg: var(--color-surface-1);
    padding: 24px;
    @extend %sys-bg;
    position: relative;
    border-radius: var(--border-radius-rounded);
    overflow: auto;
    height: auto;

    .lists-card {
      background: transparent;
    }
  }

  .mat-dialog-actions {
    min-height: 4.5rem;
  }

  .mdc-dialog__title::before {
    display: none;
  }
}

.has-datepicker {
  mat-dialog-container {
    overflow: visible !important;

    .mat-mdc-dialog-content {
      overflow: visible !important;
    }
  }
}

.typo-title,
.log-footer {
  color: var(--m3-sys-on-surface) !important;
}

.log-content,
.log-content-details {
  background-color: var(--m3-sys-surface-variant) !important;

  .log-content-header strong,
  .log-content-header span,
  .log-content-create-title {
    color: var(--m3-sys-on-surface-variant) !important;
  }
}

.log-content-details {
  margin-bottom: 0 !important;
}

.log-content__collapsable .mat-expansion-panel-header {
  background-color: var(--m3-sys-surface-variant) !important;
}

.mat-expansion-panel-header-title {
  color: var(--m3-sys-on-surface-variant) !important;
}

.mat-expansion-panel-body {
  color: var(--m3-sys-on-surface) !important;
}

.log-content-details strong {
  color: var(--m3-sys-on-surface-variant) !important;
}

.log-content-details .original-value {
  color: var(--m3-sys-on-surface-variant) !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: var(--m3-sys-on-surface-variant) !important;
}

.log-content-details .mat-icon {
  color: var(--m3-sys-on-surface-variant) !important;
}

.log-content-create {
  background-color: var(--m3-sys-secondary-container) !important;

  .log-content-header strong,
  .log-content-header span,
  .log-content-create-title {
    color: var(--m3-sys-on-secondary-container) !important;
  }
}

.animate__slideInRight {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.dialog-content {
  max-height: 70vh;
  overflow: auto;
}

.dialog__height mat-dialog-content {
  @include media-breakpoint-down(xl) {
    max-height: 70vh;
    overflow: auto;
  }
}


.dialog__overflow_visible mat-dialog-content {
  overflow: visible;
}

@media print {
  @page {
    overflow: hidden;
  }

  .cdk-global-scrollblock {
    overflow: auto !important;
    position: static !important;
    width: auto !important;
  }

  .cdk-overlay-container {
    position: absolute !important;
    height: fit-content !important;
  }

  .cdk-global-overlay-wrapper {
    position: relative !important;
    height: fit-content !important;
  }

  body .mat-dialog-container {
    position: relative !important;
    height: fit-content !important;
    max-height: initial !important;
    border-radius: unset;

    &::-webkit-scrollbar {
      width: 0 !important
    }

  }

  .dialog__height .mat-dialog-content {
    max-height: 100%;
    padding: 8px 16px !important;

  }

  .dialog__height {
    height: auto !important;
  }

  .print-none {
    display: none;
    visibility: hidden;
  }

  *::-webkit-scrollbar {
    width: 0 !important
  }

  * {
    -ms-overflow-style: none;
  }

  * {
    overflow: -moz-scrollbars-none;
  }
}

body .mat-dialog-no-scroll {

  .mat-dialog-container,
  .mat-dialog-content {
    overflow: visible;
  }
}

.dialog-content-overflow-auto .mat-dialog-content {
  overflow-y: auto;
}

body {
  .dialog-content {
    max-height: 75vh;

    p {
      font-size: var(--font-size-s);
      font-weight: 400;
    }
  }

  .dialog--h-50 {
    .dialog-content {
      max-height: 50vh;
    }
  }

  .dialog-header {
    margin-bottom: var(--space-base);
  }

  .dialog--center {
    .dialog-header {
      text-align: center;
    }
  }
}

.mat-dialog-content {
  .lists-card {
    background: none;
  }

  .lists-card .mat-list-base .mat-list-item .mat-list-item-content-reverse,
  .lists-card .mat-list-base .mat-list-option .mat-list-item-content-reverse {
    padding: var(--space-s) var(--space-none) !important;
  }

  .lists-card .mat-list-base .mat-list-option,
  .lists-card .mat-list-item-content {
    border-bottom: 1px solid var(--m3-sys-outline-variant);

    &:last-child {
      border-bottom: 0;
    }
  }

  .lists-card .mat-list-option:hover,
  .lists-card .mat-list-option:focus,
  .lists-card .mat-nav-list .mat-list-item:hover,
  .lists-card .mat-nav-list .mat-list-item:focus,
  .lists-card .mat-action-list .mat-list-item:hover,
  .lists-card .mat-action-list .mat-list-item:focus {
    background: none;
  }
}

body {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;
    overflow: unset;
  }

  .mat-mdc-dialog-container .mdc-dialog__title {
    color: var(--m3-sys-on-surface);
    font-size: var(--font-size-h4);
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content {
    color: var(--m3-sys-on-surface);

  }
}

.mat-mdc-dialog-title {
  margin: 0 0 8px;
  display: block;
}

body .mdc-dialog__actions {
  padding: 8px 0 !important;
  align-items: center !important;
  box-sizing: content-box !important;
  margin-bottom: -24px;
}

body .dialog-md .mat-dialog-content {
  padding: 8px 24px !important;
}

body {
  .mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    display: block;
    margin: 0 -24px;
    padding: 8px 24px;
    max-height: 100%;
    overflow: auto;
  }

  .mdc-dialog__container {
    min-width: 100% !important;
  }

  .mat-mdc-dialog-container .mdc-dialog__content {
    color: var(--color);
  }
}

body .mdc-dialog__content>p {
  margin-bottom: 1rem !important;
}
@keyframes fadeAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body .filter .mat-menu-panel,
body .filter .mat-menu-content {
  background: var(--color-surface-2);
}

body .filter-menu,
.mat-menu-content {
  min-width: 260px;
  max-width: fit-content;
  background-color: var(--m3-sys-surface) !important;
}

body .filter-menu--more-options,
.mat-menu-content {
  min-width: 150px;
}

body .filter-menu {
  overflow: visible !important;
  box-shadow: none;

  &--with-overlay {
    &::before {
      content: "";
      animation-name: fadeAnimation;
      animation-duration: 1s;
      position: fixed;
      top: 0;
      left: 0;
      background: rgb(0 0 0 / 12%);
      width: 100%;
      height: 100%;
    }
  }

  &--more-options {
    .mat-mdc-menu-item-text {
      justify-content: flex-start !important;
    }
  }

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background-color: var(--m3-sys-surface-variant);
  }

  .menu-item-error {
    color: var(--m3-sys-error);

    &--icon {
      svg {
        fill: var(--m3-sys-error) !important;
      }
    }
  }

  .mat-menu-submenu-icon {
    display: none;
  }

  .mat-menu-item--selected {
    background-color: var(--m3-sys-surface-variant);
    border-radius: 0;
  }

  .mat-menu-item {
    border-radius: 0 !important;
  }

  .mat-menu-item .mat-icon {
    margin-right: var(--space-none);
    margin-inline-end: var(--space-s);
  }

}

body .filter-menu .mat-menu-content {
  background: var(--color-surface-3);
  border-radius: 8px;
  overflow: auto;
  max-height: 460px;
}

body .filter-menu .mat-menu-panel .mat-menu-item,
body .filter-menu .mat-menu-content .mat-menu-item {
  border-radius: var(--space-none);
  height: 40px;
  padding: var(--space-none) var(--space-m);
  font-size: var(--font-size-base);
  margin-bottom: var(--space-xs);
  max-width: 400px;

  .filter-menu-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child {
    margin-bottom: var(--space-none);
  }
}

.custom-filter-input {
  max-width: 170px;
  transition: opacity .4s ease-in-out;

  &--hide {
    opacity: 0 !important;
  }
}

.order-btn {
  height: 0 !important;
}

.mat-menu-item .filter-menu__icon {
  .mat-icon {
    margin-right: var(--space-none) !important;
  }
}

body {
  .filter__dropdown-button {
    background-color: var(--color-surface-2);
    color: var(--m3-sys-on-surface);
    border-radius: var(--border-radius-soft);
    margin-inline-end: 12px;
    min-width: 145px;

    // max-width: -webkit-fill-available;
    .mat-button-wrapper {
      display: flex;
      justify-content: space-between;
    }

    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.filter-field-wrapper {
  width: 100px;
}

.filter-menu {
  .mat-mdc-radio-group {
    width: 230px;
  }

  .mat-mdc-menu-content {
    max-height: 60vh;
    overflow-y: auto;
  }

  .mat-mdc-menu-panel .mat-mdc-menu-item,
  .mat-mdc-menu-content .mat-mdc-menu-item {
    border-radius: 0;
  }

  .mat-mdc-menu-item-text {
    justify-content: space-between;
  }

  margin-top: var(--space-s);

  // Adjusting the new class names for mat-form-field
  .mdc-text-field--outlined {
    height: 40px;
  }

  .mat-mdc-form-field-infix {
    height: 40px;
    min-height: 40px;
    align-items: center;
    display: flex;
  }

  .mat-mdc-form-field {
    margin-bottom: var(--space-none);

    &-appearance-outline {
      .mat-mdc-form-field-wrapper {
        padding-bottom: var(--space-none);
      }

      .mat-mdc-form-field-outline {
        height: 40px;
      }

      .mat-mdc-form-field-flex {
        min-height: auto;
        padding: 0.75em;
        margin-top: var(--space-none);
        height: 40px;
      }

      .mat-mdc-form-field-infix {
        padding: var(--space-none);
      }
    }
  }
}

body {
  .filter__button {
    margin-inline-end: 12px;

    .mat-badge-content {
      background: var(--m3-sys-light-primary);
      position: initial;
    }

    .mat-badge {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &[aria-expanded="true"] {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      position: relative;
      z-index: 999;
    }
  }

  .filter,
  .filter__dropdown {
    .filter__button {
      border: 1px solid transparent;
    }

    .filter__button[aria-expanded=true] {
      box-shadow: var(--m3-sys-elevation-3);
      border: 1px solid var(--m3-sys-primary);
      background-color: var(--m3-sys-surface);
    }

    svg {
      fill: var(--m3-sys-on-surface) !important;
    }
  }

  .filter__dropdown--view--popup {
    --bg: var(--m3-sys-background);
    --fg: var(--color-surface-1);
    background-color: var(--bg);
    background: linear-gradient(0deg, var(--fg), var(--fg)), var(--bg);
  }

  .filter__dropdown-button[aria-expanded=true] {
    box-shadow: var(--m3-sys-elevation-3);
    border: 1px solid var(--m3-sys-primary);
    background-color: var(--m3-sys-surface);
  }

  .condition-button {
    svg {
      fill: var(--m3-sys-on-surface) !important;
    }
  }
}


body {
  .filter__button {
    margin-inline-end: 12px;
    padding: 0 1.5rem;

    .mat-badge-content {
      background: var(--m3-sys-light-primary);
      position: initial;
    }

    .mat-badge-medium .mat-badge-content {
      width: 22px;
      height: 22px;
      line-height: 22px;
    }

    .mat-badge {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &[aria-expanded="true"] {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
      position: relative;
      z-index: 999;
    }
  }
}

.filter__button {
  background-color: var(--color-surface-2) !important;
  color: var(--m3-sys-on-surface) !important;

  &--has-conditions {
    background-color: var(--m3-sys-primary-container) !important;
    color: var(--m3-sys-on-tertiary-container) !important;
  }

  &--more-options {
    min-width: auto !important;
    padding: 0 0.25rem !important;
  }
}

[dir="rtl"] {
  .filter-menu {
    .mat-mdc-select-value {
      padding-bottom: 5px;
    }
  }
}
body {
  .mat-progress-bar-buffer {
    background-color: var(--m3-sys-surface-variant);
  }

  .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: var(--m3-sys-primary);
  }

  .mdc-linear-progress__buffer-bar {
    background-color: var(--m3-sys-surface-variant);
  }

  .mdc-linear-progress__bar-inner {
    border-color: var(--m3-sys-primary);
  }
}
@mixin colors-variation($colors, $isGradient: false) {
  :root {

    @each $label,
    $color in $colors {
      --#{$label}: #{$color};
    }
  }

  #{$light-theme}{
    @each $label,
    $color in $colors {
      @if (str-index($label, '-light')) {
        $classLabel: str-replace($label, '-light-', '-');
        --#{$classLabel}: var(--#{$label});
      }
    }
  }
  #{$dark-theme}{
    @each $label,
    $color in $colors {
      @if (str-index($label, '-dark')) {
        $classLabel: str-replace($label, '-dark-', '-');
        --#{$classLabel}: var(--#{$label});
      }
    }
  }

  // @each $label,
  // $color in $colors {
  //   @if $isGradient ==false {
  //     .color-#{$label} {
  //       color: var(--#{$label}) !important;
  //     }
  //   }
  // }

  // @each $label,
  // $color in $colors {
  //   @if $isGradient ==false {
  //     .background-#{$label} {
  //       background-color: var(--#{$label}) !important;
  //     }
  //   }

  //   @if $isGradient ==true {
  //     .background-#{$label} {
  //       background: var(--#{$label}) !important;
  //     }
  //   }
  // }

  // @each $label,
  // $color in $colors {
  //   @if $isGradient ==false {
  //     .border-color-#{$label} {
  //       border-color: var(--#{$label}) !important;
  //     }
  //   }
  // }

  // @each $label,
  // $color in $colors {
  //   @if $isGradient ==false {
  //     .stroke-#{$label} {
  //       stroke: var(--#{$label}) !important;
  //     }
  //   }
  // }

  // @each $label,
  // $color in $colors {
  //   @if $isGradient ==false {
  //     .fill-#{$label} {
  //       fill: var(--#{$label}) !important;
  //     }
  //   }
  // }
}

body {

  .mat-mdc-menu-panel,
  .mat-mdc-menu-content {
    // --bg: var(--color-surface-3);
    // --fg: var(--m3-sys-surface);
    @extend %sys-bg;
    position: relative;
    box-shadow: var(--m3-elevation-light-2);
    border-radius: var(--border-radius-tiny);

    .mat-mdc-menu-item {
      color: var(--m3-sys-on-surface);
      font-size: var(--typo-title-small);
      font-weight: var(--font-regular);
      border-radius: var(--border-radius-rounded);
      display: flex;
      align-items: center;

      .mat-icon {
        color: currentColor;
      }

      svg {
        fill: var(--m3-sys-on-surface);
      }

      &[disabled] {
        opacity: 0.4;
      }
    }
  }
}

.mat-tooltip {
  background-color: var(--m3-sys-inverse-surface);
  color: var(--m3-sys-inverse-on-surface) !important;
  //   color: var(--m3-sys-surface) !important;
  // background-color: var(--m3-sys-on-background) !important;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.custom-menu {
  .dropdown-menu {
    padding: 0 !important;

  }

  .list-item-content--full-width {
    .mat-list-item-content {
      width: 100% !important;
    }
  }
}

.mat-input-element {
  color: var(--m3-sys-on-surface) !important;
}

.menu--form {

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    width: 1.25rem;
    height: 1.25rem;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    display: flex;
    padding: 3px;
    border: 2px solid var(--m3-sys-on-surface-variant);
    border-radius: var(--border-radius-circle);
  }

  .mat-mdc-icon-button:hover {
    background: transparent;
  }
}

body {

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item {
    @extend %sys-bg;
  }

  .mat-mdc-menu-item {
    .icon {
      fill: var(--m3-sys-on-surface);
    }
  }

  .user-menu .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
  }

  .user-menu .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 14px;
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    line-height: normal;
  }


  .mat-mdc-menu-panel {
    overflow: hidden;
  }

  .mat-mdc-menu-item:not([disabled]):hover {
    border-radius: 0;
  }


  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    display: flex;
    align-items: center;
  }
}

.user-profile {
    display: flex;
    line-height: 1.4;
    text-align: start;
    align-items: center;
}

.user-profile__avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-inline-end: var(--space-s);
    padding: var(--space-none);
    background-color: var(--m3-sys-secondary);
    color: var(--m3-sys-on-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-profile__account {
    display: flex;
    flex-direction: column;
    font-size: var(--typo-label-medium);
}

.user-profile__account-name {
    color: var(--m3-sys-on-surface);
    letter-spacing: 0.5px;
    font-weight: var(--font-medium);
    text-transform: capitalize;
}

.user-profile__account-organization {
    font-size: .9em;
    color: var(--m3-sys-outline);
    letter-spacing: 0.5px;
    font-weight: var(--font-medium);
    margin-top: 2px;
}

body .mat-mdc-icon-button {
    .user-profile__mob-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
}
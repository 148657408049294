body {
  .mat-standard-chip {
    border-radius: 8px;
    padding: var(--space-xs) var(--space-base);
    border: 1px solid var(--color-surface-1);
    font-weight: 500;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    background: transparent;
    color: var(--m3-sys-surface);
    border-color: var(--color-surface-1);
    font-weight: 500;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: var(--m3-sys-surface);
    opacity: 1;
  }

  .chip-elevated.mat-standard-chip.mat-chip-selected.mat-primary {
    border: 0;
    color: var(--m3-sys-on-surface);
    background-color: var(--color-surface-1);
    box-shadow: var(--m3-sys-elevation-1);
  }

  .avatar-chip.mat-chip.mat-standard-chip {
    border-radius: var(--border-radius-rounded);
    background-color: transparent;
  }

  // .avatar-chip.mat-standard-chip::after {
  //   background: var(--m3-sys-surface);
  // }

  .mat-chip {
    .mat-icon {
      color: var(--m3-sys-surface);
    }
  }

  .mat-chip.mat-standard-chip {
    color: var(--m3-sys-surface);
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: var(--m3-sys-surface);
    opacity: 1;
  }

  .mat-accent.mat-chip {

    .mat-icon {
      color: var(--m3-sys-on-secondary-container);
    }
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
    background-color: var(--m3-sys-secondary-container);
    border: 0;
    color: var(--m3-sys-on-secondary-container);
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
    opacity: 1;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-chip-with-trailing-icon {
    color: var(--m3-sys-surface);
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent.mat-chip-with-trailing-icon {
    color: var(--m3-sys-on-secondary-container);
  }
}

body {
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    color: var(--m3-sys-surface);
    border-color: var(--color-surface-1);
    font-weight: 500;
    border-radius: 8px;
    padding: var(--space-xs) var(--space-base);
    border: 1px solid var(--color-surface-1);
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding: 0;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: var(--m3-sys-surface);
    font-weight: 500;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).chip-elevated {
    border: 0;
    color: var(--m3-sys-on-surface);
    background-color: var(--color-surface-1);
    box-shadow: var(--m3-sys-elevation-1);
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--primary {
    fill: var(--m3-sys-on-surface-variant);
    width: 24px;
    height: 24px;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing {
    fill: var(--m3-sys-surface);
    opacity: 1;
    width: 18px;
    height: 18px;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-with-trailing-icon {
    padding: var(--space-xs) var(--space-none);
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mdc-evolution-chip--with-avatar {
    padding: var(--space-xs) var(--space-none);
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-accent {
    background-color: var(--m3-sys-secondary-container);
    border: 0;
    color: var(--m3-sys-on-secondary-container);
  }

  .mat-mdc-chip:hover .mat-mdc-chip-focus-overlay {
    opacity: 0.2;
  }

  .custom-chip-list {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-accent {
      background-color: var(--m3-sys-primary);
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
      color: var(--m3-sys-on-primary);
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing svg {
      fill: var(--m3-sys-on-primary);
    }
  }
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background-color: var(--m3-sys-inverse-on-surface);
}
.mat-mdc-standard-chip.mat-primary {
  background-color: var(--m3-sys-primary)
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing{
  color: var(--m3-sys-surface);
}
.chip-list-field {
  .mat-mdc-standard-chip{
    width: 100%;
    .mdc-evolution-chip__action{
      justify-content: start;
    }
  }
}
}





body {

  .mat-checkbox-frame {
    border-color: var(--m3-sys-on-surface);
  }

  .mdc-checkbox__checkmark-path {
    stroke: var(--m3-sys-surface) !important;
  }

  .mat-warn {
    .mdc-checkbox__checkmark-path {
      stroke: var(--m3-sys-on-error) !important;
    }
  }

  .mdc-checkbox--disabled {
    opacity: 0.38;
  }

  .mdc-checkbox--disabled .mat-checkbox-label {
    color: var(--m3-sys-on-surface);
  }

  .mdc-checkbox--disabled.mat-mdc-checkbox-checked .mdc-checkbox__background,
  .mdc-checkbox--disabled.mat-checkbox-indeterminate .mdc-checkbox__background {
    background-color: var(--m3-sys-on-surface);
  }

  .mat-pseudo-checkbox {
    color: var(--m3-sys-on-surface-variant);
    border-color: currentColor;
  }
}

.mat-mdc-checkbox.checkbox_selectRow {
  .mat-mdc-checkbox .mdc-form-field {
    height: 40px;
  }
}

.radio-button-with-border {
  margin-bottom: var(--space-s);
  border: 1px dashed lightgray;
  border-radius: 28px;
  padding: 10px;
  width: 100%;
  margin-inline-start: -3px
}

.radio-button-card {
  background-color: var(--color-surface-2);
  margin-bottom: var(--space-base);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;

  &.mat-mdc-radio-button .mat-internal-form-field {
    width: 100%;

    .mdc-label {
      margin-inline-start: 0 !important;
      margin-inline-end: auto !important;
    }
  }
}

.mat-mdc-checkbox {
  --mdc-checkbox-unselected-focus-icon-color: var(--m3-sys-on-surface);
}

.mat-mdc-checkbox-touch-target {
  height: fit-content !important;
  width: fit-content !important;
}
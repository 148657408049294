body {
  .user-menu--organization-true {
    width: 330px;
    max-width: 330px;
  }

  .user-menu {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: all .15s ease-in-out;

    .dialog__height mat-dialog-content {
      max-height: 100%;
      padding: 8px 24px !important;
      margin: 0 -24px;
    }

    .mat-mdc-menu-item {
      color: var(--m3-sys-on-surface-variant);
      font-size: var(--typo-title-small);
      margin: var(--space-xs) var(--space-none);
      display: flex;
      justify-content: space-between;

      .mat-icon {
        margin-inline-end: 0;

        [dir="rtl"] & {
          margin-left: 0;
          transform: scaleX(-1);
        }
      }
    }

    .user-menu__inner {
      .mat-mdc-menu-item-text {
          justify-content: space-between;
        }
      .card-filled{
        box-shadow: var(--m3-sys-elevation-1) !important;
      }
    }
    

    .user-menu__inner-icon,
    .menu-arrow {
      [dir="rtl"] & {
        transform: scaleX(-1);
      }
    }

    .menu-arrow {
      margin-inline-start: auto;
    }

    .mat-mdc-menu-content:not(:empty) {
      padding: var(--space-m) var(--space-base) var(--space-base);
      border-radius: 15px;
      box-shadow: none;

      [dir="rtl"] & {
        text-align: right;
        direction: rtl;
      }
    }

    .mat-mdc-menu-item .mat-icon-no-color,
    .mat-menu-submenu-icon {
      color: var(--m3-sys-on-surface-variant);
    }

    .user-menu__hide {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all .1s linear;
    }

    .user-menu__show {
      opacity: 1;
      visibility: visible;
      // max-height: 450px;
      display: flex;
      flex-direction: column;
      transition: all .2s linear;

      .mat-mdc-menu-item:not([disabled]):hover {
        border-radius: var(--border-radius-rounded);
      }
    }

    .language-hide {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all .1s linear;
    }

    .language-show {
      opacity: 1;
      visibility: visible;
      transition: all .2s linear;
    }

    .search-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }

    .search-list {
      height: 100%;
      overflow: auto;
      border-radius: 0 0 28px 28px;
      min-height: 72px;
    }

    .user-menu__inner-header {
      position: sticky;
      top: 0;
    }
  }

  .user-menu__inner-title {
    font-size: var(--font-size-base);
    margin: 0;
    line-height: 40px;
    font-weight: 500;
    color: var(--m3-sys-on-surface);
  }

  .user-menu__inner-button {
    display: flex;
    padding: var(--space-none) var(--space-m);
    width: 100%;
    color: var(--m3-sys-on-surface-variant);
    margin-bottom: var(--space-s);

    .mat-button-wrapper {
      justify-content: flex-start;
    }

  }

  .user-menu__inner-checked-icon {
    display: none;
  }


  .user-menu__inner .user-menu__inner-button--selected {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-secondary-container);
    display: flex;
    justify-content: space-between;
    align-items: center;

    // &::after {
    //   content: "done";
    //   position: static;
    //   font-family: var(--material-font);
    //   font-size: 20px;
    //   text-transform: none;
    //   color: var(--m3-sys-on-secondary-container);
    //   display: flex;
    //   justify-content: flex-end;
    //   margin-inline-start: auto;
    // }

    .user-menu__inner-checked-icon {
      display: block;
      font-size: 20px;
      text-transform: none;

      svg {
        fill: var(--m3-sys-on-secondary-container);
      }
    }
  }

  .user-menu__inner {
    .mat-form-field-wrapper {
      padding-bottom: var(--space-none);
    }

    .mat-form-field {
      margin-bottom: var(--space-m);
    }
  }
}

.user-menu__inner {
  .mat-form-field-appearance-outline .mdc-notched-outline__leading {
    border-radius: 28px 0 0 28px !important;
    width: 40px !important;
  }

  .mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    border-radius: 0 28px 28px 0 !important;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    margin-inline-start: var(--space-base);
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    padding: var(--space-none) var(--space-base);
    min-height: 44.8px !important;
  }

  .mat-form-field-appearance-outline mat-label:not(.form-placeholder),
  .mat-form-field-appearance-outline .mat-placeholder-required {
    font-size: var(--typo-body-large);
    color: var(--m3-sys-on-surface-variant);
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding: 12.8px 0;
  }

  .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
    --mat-mdc-form-field-label-offset-x: unset !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 44.8px;
  }

  .mat-mdc-floating-label {
    margin-top: -.6em;
    top: 1.91875em;
  }
}

.user-menu__search-field {

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    height: 24px;
  }

  .mat-input-element {
    line-height: 24px;
  }
}

[dir=rtl] .user-menu__inner {
  .mat-form-field-appearance-outline .mdc-notched-outline__leading {
    border-radius: 0px 28px 28px 0px !important;

  }

  .mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    border-radius: 28px 0px 0px 28px !important;
  }
}

:root{
  --m3-elevation-light-1: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --m3-elevation-light-2: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  --m3-elevation-light-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  --m3-elevation-light-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);
  --m3-elevation-light-5: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);

  --m3-elevation-dark-1: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  --m3-elevation-dark-2: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  --m3-elevation-dark-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  --m3-elevation-dark-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);
  --m3-elevation-dark-5: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
}

#{$light-theme}{
  --m3-sys-elevation-1: var(--m3-elevation-light-1);
  --m3-sys-elevation-2: var(--m3-elevation-light-2);
  --m3-sys-elevation-3: var(--m3-elevation-light-3);
  --m3-sys-elevation-4: var(--m3-elevation-light-4);
  --m3-sys-elevation-5: var(--m3-elevation-light-5);
}

#{$dark-theme}{
  --m3-sys-elevation-1: var(--m3-elevation-dark-1);
  --m3-sys-elevation-2: var(--m3-elevation-dark-2);
  --m3-sys-elevation-3: var(--m3-elevation-dark-3);
  --m3-sys-elevation-4: var(--m3-elevation-dark-4);
  --m3-sys-elevation-5: var(--m3-elevation-dark-5);
}

.elevation-1 {
  box-shadow: var(--m3-sys-elevation-1) !important;
}

.elevation-2 {
  box-shadow: var(--m3-sys-elevation-2) !important;
}

.elevation-3 {
  box-shadow: var(--m3-sys-elevation-3) !important;
}

.elevation-4 {
  box-shadow: var(--m3-sys-elevation-4) !important;
}

.elevation-5 {
  box-shadow: var(--m3-sys-elevation-5) !important;
}

  body {
  .mat-badge-medium .mat-badge-content {
    width: 6px;
    height: 6px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: normal;
  }

  .badge-md {
    .mat-badge-content {
      width: 16px;
      height: 16px;
      padding: 4px;
    }
  }

  .badge-lg {
    .mat-badge-content {
      width: 22px;
      height: 16px;
      padding: 4px 5px;
      width: auto;
      border-radius: 100px;

    }
  }

  .mdc-tab__text-label .mat-badge-content {
    width: auto !important;
    min-width: 30px;
    min-height: 22px;
    padding: 2px;
    border-radius: 4px !important;
    transform: translate(90%) !important;
  }

  [dir=rtl] & {
    .mdc-tab__text-label .mat-badge-after .mat-badge-content {
      transform: translate(-90%) !important;
      line-height: var(--mat-badge-legacy-container-size, var(--mat-badge-container-size));
    }
  }


  .mat-badge-medium.mat-badge-overlap .mat-badge-content {
    width: 20px;
    height: 20px;
    background-color: var(--m3-sys-primary) !important;
  }
}

.note-alert {
    display: flex;
    align-items: stretch;
    background-color: #fff7e6;
    color: #333;
    border-radius: 10px;
    border-left: 10px solid #ffe8a1;
}

.note-alert__icon-box {
    background-color: #ffe8a1;
    padding: 10px 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

[dir="rtl"] .note-alert {
    border-left: unset !important;
    border-right: 10px solid #ffe8a1 !important;
}

[dir="rtl"] .note-alert__icon-box {
    padding: 10px 0 10px 10px !important;
}

#{$dark-theme} {
    .note-alert {
        background-color: #5A4300;
        color: #FFDF9B;
        border-color: #3F2E00 !important;
    }

    .note-alert__icon-box {
        background-color: #3F2E00;
    }
}

:root {
    --highlight-tertiary: var(--m3-ref-tertiary-tertiary95);
}

#{$dark-theme} {
    --highlight-tertiary: var(--m3-ref-tertiary-tertiary30);
}
.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.m-0 {
  margin: 0 !important;
}

.is-hidden {
  display: none !important;
  visibility: hidden;
}

.l-f-end {
  display: flex;
  justify-content: flex-end;
}

.l-f-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-f-between-col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mat-menu-panel {
  overflow: hidden !important;
}

.full-height {
  height: 100%;
}

hr {
  margin: 1px 0;
}

.footer {
  padding: 1em;
  font-size: 13px;

  @include breakpoint(isMobile) {
    text-align: center;

    &__start {
      margin-bottom: var(--space-base);
    }

    &__end {
      justify-content: center !important;
      margin-bottom: var(--space-base);
    }

    &__center {
      order: -1;
      margin-bottom: var(--space-base);
    }
  }
}

.footer__block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}




.roundCell {
  padding: 0 24px;
  height: 25px;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 25px;
  align-items: center;
  top: 10px;
  position: relative;
}

.roundCell.loss {
  border-color: var(--m3-sys-error)
}

.loss {
  color: var(--m3-sys-error);
}

.roundCell.gain {
  border-color: rgb(76, 175, 80);
}

.gain {
  color: rgb(76, 175, 80);
}

.line-through {
  text-decoration: line-through;
}

.mat-tab-label.mat-tab-label-active {
  .mat-tab-label-content {
    color: var(--m3-sys-primary);
    font-weight: 500;
  }

  .mat-icon {
    color: var(--m3-sys-primary);
  }
}

.mat-tab-label-content {
  >div {
    display: flex;
    justify-content: center;
    align-items: center;

    icon {
      margin-inline-end: 8px;
      margin-inline-end: 8px;
    }
  }
}

.mat-tab-label,
.mat-tab-link {
  color: var(--m3-sys-on-surface);
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-color: var(--m3-sys-surface-variant);
}

.ag-theme-material {
  width: 100%;
  height: calc(100vh - 320px);

  &--md {
    height: calc(100vh - 370px);
  }

  &--has-filter {
    height: calc(100vh - 335px);
    // .ag-header-icon{
    //   display: none;
    // }
  }

  font-family: var(--font-family);
  font-size: 13px;
  line-height: normal;

  .mdc-form-field {
    font-size: 13px;
  }
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #404041;
}

.ag-theme-material .ag-header {
  background-color: var(--m3-sys-surface);
}

.ag-column-select-column {
  .ag-drag-handle {
    order: 1;
  }

  .ag-column-select-checkbox {
    order: 2;
  }

  .ag-column-select-column-label {
    order: 3;
  }
}

.ag-floating-bottom-viewport {
  font-weight: 600;
}

//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: 1px solid $color-primary;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  position: relative;
  top: -4px;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  background-color: currentColor;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
}

.mat-tab-labels .mat-badge-content {
  width: auto !important;
  min-width: 30px;
  min-height: 22px;
  text-align: center;
  align-items: center;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  height: auto !important;
  transform: translate(90%, 0) !important;
  border-radius: 4px !important;
}

[dir="rtl"] .mat-tab-labels .mat-badge-content {
  transform: translate(-90%, 0) !important;
}


.u-color-white {
  color: $color-white !important;
}


.u-elem-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 0.3;
  z-index: -1;
}

.u-text-center {
  text-align: center;
}

.u-flex-grid {
  padding: 0;
  margin: 0 15px;
}

.u-hidden-md {
  @include breakpoint(isCommon) {
    display: none;
  }
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-20 {
  margin-top: 20px;
}

.u-justify-between {
  display: flex;
  justify-content: space-between;
}

.u-position-relative {
  position: relative !important;
}

.empty-div {
  padding: 20px;
}

.u-padding-0 {
  padding: 0;
}

.matInput--full {

  .mat-form-field,
  .filter-panel__date-range {
    width: 100%;
  }
}

.button-group--basic {
  .mat-button-toggle-group {
    border: 0;
  }

  .mat-button-toggle-label-content {
    font-size: 14px;
    font-weight: 500;
  }

  .mat-button-group--drop-down {
    .mat-button-toggle-label-content {
      padding: 0 8px;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
  }
}

.button-group--primary {
  .mat-button-toggle-group {
    border-color: var(--m3-sys-primary);
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    color: var(--m3-sys-on-primary);
  }

  .mat-button-toggle {
    background-color: var(--m3-sys-primary);
    color: var(--m3-sys-on-primary);
  }

  .mat-button-group--drop-down {
    border-left: 1px solid;
  }

  [dir="rtl"].mat-button-group--drop-down {
    border-left: unset;
    border-right: 1px solid;
  }
}

.mat-select-actions {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    .mat-select-actions__buttons {
      opacity: 1;
    }
  }
}

.mat-option {
  flex: 1;
}

.mat-select-actions__buttons {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 10;
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: 0;

  .mat-button-base {
    margin: 0;
    opacity: 0.54;
  }

  .mdc-list-item--selected:has(mat-pseudo-checkbox) & {
    right: 40px;
  }
}

[dir="rtl"] .mat-select-actions__buttons {
  right: auto;
  left: 10px;
}

[dir="rtl"] .mdc-list-item--selected:has(mat-pseudo-checkbox) .mat-select-actions__buttons {
  right: auto;
  left: 40px;
}

.d-print-none {
  @media print {
    display: none;
  }
}

[dir="rtl"] .u-filp-ar {
  transform: scale(-1);
}

.u-padding-side-38 {
  padding-left: 38px;
  padding-right: 38px;
}

.spinner--white {

  &.mat-spinner {
    circle {
      stroke: #fff;
    }
  }
}

.mat-elevation-z1 {
  box-shadow: none;
}


.ag-theme-material .ag-row {}

.ag-header-cell-label {
  justify-content: center;
}

.custom-stock-items-table,
.custom-import-sales-orders-table,
.custom-register-closures-table {
  .ag-header-cell-label {
    justify-content: start;
  }
}

.custom-stock-items-table.ag-theme-material {
  height: calc(100vh - 246px);
}


.mat-option .mat-icon {
  margin-right: 0 !important;
}

.drop-down-icon {
  .mat-icon {
    color: var(--m3-sys-dark-inverse-primary) !important;
  }
}
.dropzone {
    min-height: 350px;
    min-width: 400px;
    display: table;
    width: 100%;
    margin: auto;
    color: var(--m3-sys-on-surface);
    background-color: var(--m3-sys-background) !important;
    border: 4px dashed var(--m3-sys-light-inverse-on-surface);

    @include media-breakpoint-down(sm) {
        min-width: 100%;
        margin-bottom: var(--space-s);
    }

    p {
        font-family: Lato;
    }

    .text-wrapper {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .hide {
        display: none;
    }

    .upload-icon svg {
        margin-bottom: 10px;
        fill: rgb(189, 189, 189);
        font-size: 48px;
        width: 48px !important;
        height: 48px !important;
    }

    .description-icon svg {
        margin-bottom: 10px;
        fill: var(--m3-sys-on-surface);
        width: 64px !important;
        height: 64px !important;
    }

    .centered {
        font-size: 1.125rem;
        text-align: center;
        color: rgb(189, 189, 189);
        margin-bottom: 0.625rem;
    }

    .file {
        vertical-align: middle;
        text-align: center;
        position: relative;
        width: 9.375rem;
        margin: auto;
        padding: 3rem 1rem;
        display: block;
        border: 1px solid rgb(245, 245, 245);
        overflow-wrap: break-word;
    }

    .file-icon {
        font-size: 48px;
        display: block;
    }

    .delete {
        font-size: 18px;
        color: var(--m3-sys-error);
        position: absolute;
        top: 0px;
        right: 0px;

        .material-icons {
            color: var(--m3-sys-error);
        }
    }

    .mat-progress-bar {
        height: 14px;
    }
}
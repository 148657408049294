body .form-group__swtich {
  .mat-slide-toggle {
    border-color: var(--m3-sys-on-surface);
    height: auto;

    .mat-slide-toggle-ripple {
      border-radius: 50%;
    }
  }

  .mat-slide-toggle-thumb {
    background-color: var(--m3-sys-outline);
  }

  .mat-slide-toggle-bar {
    background-color: var(--m3-sys-surface-variant);
    width: 52px;
    height: 32px;
    border: 2px solid var(--m3-sys-outline);
    border-radius: var(--border-radius-rounded);
  }

  .mat-slide-toggle-thumb-container {
    left: auto;
    right: 6px;
    top: 7px;
    width: 16px;
    height: 16px;
    transform: translate(-20px, 0px);

    [dir=rtl] & {
      right: auto;
      left: 48px;
    }
  }

  .mat-slide-toggle-thumb {
    height: 16px;
    width: 16px;
  }

  .mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
      border-color: var(--m3-sys-primary);
    }

    .mat-slide-toggle-thumb {
      background-color: var(--m3-sys-on-primary);
      border-color: var(--m3-sys-primary);
    }

    .mat-slide-toggle-thumb-container {
      transform: translate(0px, 0px) scale(1.3);

      [dir=rtl] & {
        transform: translate(-40px, 0px) scale(1.3);
      }

      .mat-slide-toggle-thumb {
        background-color: var(--m3-sys-on-primary);
      }
    }
  }

  .mat-slide-toggle-thumb-container {
    width: 1rem;
    height: 1rem;
    right: 0.25rem;
    top: 0.35rem;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none;
  }

  .mat-checked .mat-slide-toggle-thumb-container {
    width: 1.5rem;
    height: 1.5rem;
    top: 0.15rem;
  }

  .mat-slide-toggle.mat-disabled {
    opacity: 1;

    .mat-slide-toggle-thumb-container {
      top: 0.25rem;
    }

    .mat-slide-toggle-bar {
      border: 0;
    }
  }

  .switch-check.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
    width: 1.5rem;
    height: 1.5rem;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: unset;
  }

  .switch-check {
    .mat-slide-toggle-thumb {
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('#{$img-path}/close.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    .mat-slide-toggle-thumb-container {
      width: 1.5rem;
      height: 1.5rem;
      top: 0.1rem;
      right: 0.1rem;
    }
  }


  .switch-check.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: unset;
  }

  .switch-check.mat-checked {
    .mat-slide-toggle-thumb {
      background-image: url('#{$img-path}/check.svg');
      background-position: center;
    }
  }

  .switch-check--dark.mat-checked {
    .mat-slide-toggle-thumb {
      background-image: url('#{$img-path}/check-dark.svg');
      background-position: center;
    }
  }


  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 1.25rem);
    left: calc(50% - 1.25rem);
    height: 2.5rem;
    width: 2.5rem;
  }

  &--row {
    width: 100%;

    .mat-mdc-slide-toggle {
      width: 100%;
    }

    .mat-internal-form-field {
      width: 100%;
      justify-content: space-between;
    }

    .mdc-form-field--align-end>label {
      margin-left: 0;
    }
  }
}

body {
  .mat-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
    background-color: var(--m3-sys-surface);
  }

  .mdc-switch {
    width: 52px;
    height: 32px;
  }

  .mdc-switch .mdc-switch__track {
    width: 100%;
    height: 100%;
  }

  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
    background-color: var(--m3-sys-primary);
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background-color: var(--m3-sys-primary);
  }

  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    background-color: var(--m3-sys-primary);
  }

  .mdc-switch:enabled:active .mdc-switch__track::after {
    background-color: var(--m3-sys-primary);
  }

  .mdc-switch .mdc-switch__track {
    border-radius: var(--border-radius-rounded);
    border: 2px solid var(--m3-sys-outline);
  }

  .mdc-switch--checked .mdc-switch__track {
    border-color: transparent;
  }

  .mdc-switch__icons {
    background-color: var(--m3-sys-outline);
    height: 16px;
    width: 16px;
    border-radius: 100px;

    svg {
      display: none;
    }
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    display: none;
  }

  .mdc-switch .mdc-switch__handle::before {
    display: none;
  }

  .mdc-switch--checked .mdc-switch__icons {
    background-color: var(--m3-sys-on-primary);
    width: 1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    border-radius: 100px;

    svg {
      display: none;
    }
  }

  .mdc-switch--checked .mdc-switch__handle {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
  }

  .mdc-switch:enabled .mdc-switch__track::before {
    background-color: var(--m3-sys-surface-variant);
  }

  .mdc-switch--checked .mdc-switch__handle-track {
    width: calc(90% - var(--mdc-switch-handle-width));
  }

  .mdc-switch:enabled .mdc-switch__shadow {
    box-shadow: none;
  }

  .mdc-switch:disabled .mdc-switch__shadow {
    display: none;
  }


  .mdc-switch--unselected .mdc-switch__handle {
    width: 16px;
    height: 16px;
  }

  .mdc-switch--unselected .mdc-switch__handle {
    left: 8px;
  }

  .mdc-switch--checked .mdc-switch__handle {
    left: 0;
  }

  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after {
    display: none;
  }

  .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
    opacity: 1;
  }

  .mdc-switch:disabled .mdc-switch__track {
    opacity: 1;
  }

  .mdc-switch .mdc-switch__handle {
    border-radius: 100px;
  }

  .mdc-switch:disabled .mdc-switch__track::after {
    background-color: var(--m3-sys-primary);
  }

  .mdc-switch--checked.mdc-switch:disabled .mdc-switch__icons {
    background-color: var(--m3-sys-surface);
  }

  .mdc-switch:enabled .mdc-switch__track::before {
    width: 52px;
    height: 32px;
  }
}

[dir=rtl] body .mdc-switch--unselected .mdc-switch__handle {
  right: 8px;
}

body .mat-mdc-slide-toggle {
  .mdc-form-field--align-end {
    .mdc-label {
      padding-inline-end: 1rem;
      white-space: wrap;
    }

    .mdc-switch {
      margin-inline-start: 1rem;
    }
  }

  .mdc-switch--disabled+label {
    color: var(--m3-sys-on-surface-variant);
    opacity: .38;
  }

  .mdc-switch--disabled {
    opacity: .38;
  }

}
body {

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    justify-content: space-between;
    background-color: var(--m3-sys-surface);
  }

  .toolbar-title {
    font-size: var(--font-size-l);
    color: var(--m3-sys-on-surface)
  }

  .toolbar-gray {
    background-color: var(--color-surface-2);
  }

  .nav-bars {
    background-color: var(--color-surface-2);
  }

  .nav-bars__link {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    width: 64px;
    height: 48px;
    border-radius: var(--border-radius-soft);

    &--active {
      .nav-bars__icon {
        background-color: var(--m3-sys-secondary-container);
      }
    }
  }

  .nav-bars__icon {
    width: 100%;
    height: 32px;
    min-height: 32px;
    border-radius: var(--border-radius-rounded);
    margin-bottom: var(--space-xs);
  }

  .nav-bars__title {
    font-size: var(--font-size-xs);
    color: var(--m3-sys-on-surface);
    line-height: normal;
  }
}
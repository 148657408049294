/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "./angular-theme";

// // 3rd party libraries
// @import "~material-design-icons-iconfont/src/material-design-icons";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component


// Theme customization
// @import "theme/theme";
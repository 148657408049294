.mat-mdc-checkbox {
  svg path {
    stroke-width: 2.1px !important;
  }
}

.toggle-checked-icon {
  display: none !important;
}

.mat-button-toggle-checked {
  .toggle-checked-icon {
    display: block !important;
    font-size: 20px;
    text-transform: none;
    padding-inline-end: var(--space-s);

    svg {
      fill: var(--m3-sys-on-secondary-container);
    }
  }
}

.icon {
  &--primary {
    fill: var(--m3-sys-primary) !important;
  }

  &--warn {
    fill: var(--m3-sys-error) !important;
  }

  &--success {
    fill: #28a745 !important;
  }

  &--on-surface {
    fill: var(--m3-sys-on-surface);
  }
}

.mat-mdc-icon-button .icon--warn {
  fill: var(--m3-sys-error) !important;
}

.mat-mdc-icon-button .icon--primary {
  fill: var(--m3-sys-primary) !important;
}

icon:not([svgfill]) svg {
  fill: currentColor;
}

.icon-dark svg {
  fill: var(--m3-sys-on-surface);
}


.side-nav__link--starred-icon {
  color: var(--m3-sys-secondary) !important;
  transition: all .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
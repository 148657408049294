body {

  // .mat-slider-horizontal .mat-slider-ticks {
  //   background-color: var(--m3-sys-surface-variant);
  // }
  .mat-slider-horizontal .mat-slider-wrapper,
  .mat-slider-track-wrapper,
  .mat-slider-track-background,
  .mat-slider-track-fill,
  .mat-slider-ticks-container,
  .mat-slider-ticks {
    height: 4px !important;
  }

  .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
    background-color: var(--m3-sys-primary);
    border: 0;
  }

  .mat-slider-thumb {
    transform: none;
  }

  .mat-slider.mat-accent .mat-slider-track-fill,
  .mat-slider.mat-accent .mat-slider-thumb,
  .mat-slider.mat-accent .mat-slider-thumb-label {
    background-color: var(--m3-sys-primary);
  }

  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
    background-color: var(--m3-sys-primary);
  }

  .mat-slider-track-background {
    background-color: var(--m3-sys-surface-variant);
  }

  .mat-slider:hover .mat-slider-track-background,
  .mat-slider.cdk-focused .mat-slider-track-background {
    background-color: var(--m3-sys-surface-variant);
  }

  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
  .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
    background-color: var(--m3-sys-primary);
  }

  .mat-slider:not(.mat-slider-disabled).cdk-focused.mat-slider-thumb-label-showing .mat-slider-thumb {
    transform: none !important;
  }








  // .mat-slider.mat-accent .mat-slider-track-fill,
  // .mat-slider.mat-accent .mat-slider-thumb,
  // .mat-slider.mat-accent .mat-slider-thumb-label {

  // }
  .mat-slider-thumb {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      z-index: -1;
      border-radius: 50%;
    }
  }

  .mat-slider-horizontal .mat-slider-thumb-label {
    top: -45px;
  }

  .mat-slider-thumb {

    &:hover,
    &:active,
    &:focus {
      &::before {
        background-color: var(--m3-state-layers-light-primary-opacity-08);
      }

      // border: 10px solid #E8F0F7;
      &~.mat-slider-thumb-label {
        transform: rotate(45deg);
        border-radius: 50% 50% 0;

        .mat-slider-thumb-label-text {
          opacity: 1;
        }
      }
    }

    &:active,
    &:focus {
      &::before {
        background-color: var(--m3-state-layers-light-primary-opacity-12);
      }
    }
  }

  .mat-slider-disabled {

    .mat-slider-thumb,
    .mat-slider-track-fill {
      transform: none;
      background-color: var(--m3-sys-outline) !important;

      &::before {
        display: none;
      }
    }

    .mat-slider-track-background {
      background-color: var(--m3-state-layers-on-surface-opacity-12) !important;
    }
  }

  // .dark-theme{
  //   .mat-slider-disabled{
  //     .mat-slider-track-background{
  //       background-color: var(--m3-state-layers-dark-on-surface-opacity-12) !important;
  //     }
  //   }
  // } 
}

body {
  .mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-slider .mdc-slider__thumb-knob {
    border-color: var(--m3-sys-primary);
    background-color: var(--m3-sys-primary);
  }

  .mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }

  .mdc-slider__tick-marks {
    display: none;
  }

  .mat-mdc-slider .mdc-slider__track--inactive {
    background-color: var(--m3-sys-surface-variant);
    opacity: 1;
  }

  .mat-mdc-slider .mdc-slider__value-indicator {
    background-color: var(--m3-sys-primary);
    width: 28px;
    height: 28px;
    border-radius: 50% 50% 0;
    padding: 0;
    opacity: 1;
    left: 0;
  }

  .mdc-slider .mdc-slider__thumb--with-indicator .mdc-slider__value-indicator {
    transform: rotate(45deg);
    left: 0;
  }

  .mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: var(--m3-sys-primary);
  }

  .mat-mdc-slider .mdc-slider__value-indicator-text {
    font-size: var(--typo-label-small);
    color: var(--m3-sys-on-secondary);
    width: 100%;
    transform: rotate(-45deg) !important;
  }

  .mat-mdc-slider .mdc-slider__value-indicator-container {
    transform: none;
    left: 0;
    bottom: 46px;
  }

  .mdc-slider .mdc-slider__value-indicator::before {
    display: none;
  }

  .mdc-slider .mdc-slider__tick-marks {
    display: none;
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
    background-color: var(--m3-sys-surface-variant);
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
    background-color: var(--m3-sys-outline);
    border-color: var(--m3-sys-outline);
  }

  .mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive {
    opacity: 1;
  }

  .mat-mdc-slider.mdc-slider--disabled {
    opacity: 1;
  }
}
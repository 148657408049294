
.scoped-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
   .cdk-overlay-backdrop{
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
  }
  &--container {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--body {
    --bg: var(--m3-sys-background);
    --fg: var(--color-surface-1);
    --color: var(--m3-sys-on-surface);
    background: linear-gradient(0deg, var(--fg), var(--fg)), var(--bg);
    width: 60%;
    padding: var(--space-l);
    border-radius: 4px;
    animation-duration: .2s;
  }
  .dialog__header {
    margin-bottom: 20px;
  }
}


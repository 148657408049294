body {
  .mat-mdc-tab-labels {
    justify-content: space-around;

    @include media-breakpoint-down(sm) {
      width: fit-content;
    }
  }

  .mat-mdc-tab-label {
    padding: var(--space-none);
    color: var(--m3-sys-on-surface-variant);
    opacity: 1;

    @include media-breakpoint-down(sm) {
      min-width: fit-content;
      padding: 0 var(--space-s);
    }
  }

  .tab-fixed-assets {
    .mat-mdc-tab-label {
      @include media-breakpoint-down(sm) {
        height: 63px;
      }
    }
  }



  .tab-primary {
    .mat-ink-bar {
      height: 3px;
      border-radius: 100px 100px 0px 0px;
      margin-left: calc(var(--space-m) + 2px);
    }

    .mat-mdc-tab-label {
      min-width: unset;
    }

    &--icon {
      .mat-mdc-tab-label-content {
        display: block;
      }

      .mat-mdc-tab-label {
        padding-top: var(--space-s);
      }
    }
  }

  .tab-secondary {
    .mat-mdc-tab-labels {
      justify-content: space-between;
    }
  }

  .mat-mdc-tab-group--sm {
    .mat-mdc-tab-label {
      min-width: auto;
    }

    .mat-mdc-tab-body-wrapper {
      margin-top: var(--space-m);
    }
  }
}

.tabs-Visibility {
  &--btns {
    display: flex;
    border-bottom: 1px solid var(--m3-sys-surface-variant);

    button {
      border-radius: 0;
      border: none !important;
      border-bottom: 2px solid transparent !important;
      transform: translateY(1px);
      flex: 1;

      &.active {
        border-bottom: 2px solid !important;
      }
    }
  }
}

body {
  .mat-mdc-tab .mdc-tab__text-label {
    color: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-tab:hover .mdc-tab__text-label {
    color: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--m3-sys-primary);
  }

  .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
  .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
    background-color: transparent;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    background-color: var(--m3-sys-primary);
    border: 0;
    height: 2px;
    border-radius: 100px 100px 0px 0px;
  }


  .mat-mdc-tab .mdc-tab__ripple::before {
    background: none;
  }

  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    padding: var(--space-base) var(--space-none);
    height: fit-content;
  }

  .mdc-tab {
    min-width: 180px;

    .icon {
      fill: var(--m3-sys-on-surface-variant);
    }
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid var(--m3-sys-surface-variant);
  }

  .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label {
    color: var(--m3-sys-primary);
  }

  .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
    color: var(--m3-sys-primary);
  }

  .mat-mdc-tab .mdc-tab__text-label {
    display: flex;
  }

  [mat-align-tabs=center]>.mat-mdc-tab-header .mat-mdc-tab-labels {
    width: fit-content;
    margin: auto;
  }
}

.mdc-tab__text-label div {
  display: flex;
  justify-content: center;
  align-items: center;

  icon {
    margin-inline-end: 6px;
  }
}
$font-weights: (
  "light": 300,
  "regular": 400,
  "medium": 500,
  "semi-bold": 600,
  "bold": 700,
  "black": 900,
);

:root {
  // Typography - Size
  --font-size-display1: 4rem; // 64px
  --font-size-display2: 3.5rem; // 56px
  --font-size-h1: 2.5rem; // 40px
  --font-size-h2: 2rem; // 32px
  --font-size-h3: 1.75rem; // 28px
  --font-size-h4: 1.5rem; // 24px
  --font-size-h5: 1.25rem; // 20px
  --font-size-h6: 1rem; // 16px

  --font-size-xxl: 2.26rem; // 36px
  --font-size-xl: 1.75rem; // 28px
  --font-size-l: 1.375rem; // 22px
  --font-size-m: 1.125rem; // 18px
  --font-size-base: 1rem; // 16px
  --font-size-s: 0.875rem; // 14px
  --font-size-xs: 0.75rem; // 12px
  --font-size-tiny: 0.625rem; // 10px

  @each $weight,
  $value in $font-weights {
    --font-#{$weight}: #{$value};
  }
}

@each $weight,
$value in $font-weights {
  .font-#{$weight} {
    font-weight: var(--font-#{$weight}) !important;
  }
}

@mixin typo($name, $size, $weight: var(--font-regular), $line-height: normal, $transform: initial) {
  #{$name} {
    font-size: $size !important;
    font-weight: $weight;
    line-height: $line-height;
    text-transform: $transform;
    margin-top: 0;
  }
}

@include typo(".typo-display-1", var(--font-size-display1), var(--font-black), 105px);
@include typo(".typo-display-2", var(--font-size-display2), var(--font-black), 105px);
@include typo(".typo-display-3", var(--font-size-display2), var(--font-black), 100px);

@include typo(".h1", var(--font-size-h1), var(--font-black), 59px);
@include typo(".h2", var(--font-size-h2), var(--font-bold), 48px);
@include typo(".h3", var(--font-size-h3), var(--font-black), 50px);
@include typo(".h4", var(--font-size-h4), var(--font-bold), 42px);
@include typo(".h5", var(--font-size-h5), var(--font-black), 55px);
@include typo(".h6", var(--font-size-h6), var(--font-bold), 24px);

@include typo(".font-size-xl", var(--font-size-xl), var(--font-regular), 59px);
@include typo(".font-size-l", var(--font-size-l), var(--font-regular), 40px);
@include typo(".font-size-base", var(--font-size-base), var(--font-regular), 24px);
@include typo(".font-size-m", var(--font-size-m), var(--font-regular), 24px);
@include typo(".font-size-s", var(--font-size-s), var(--font-light), 20px);
@include typo(".font-size-xs", var(--font-size-xs), var(--font-light), 16px);

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-no-underline {
  text-decoration: none !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-justify {
  text-align: justify !important;
}


.typo-display4 {
  -webkit-font-smoothing: antialiased;
  font-size: 7rem;
  font-weight: 300;
  letter-spacing: -0.04em;
  line-height: 7rem;
  margin: -1rem 0 3.5rem -0.085em;
}

.typo-display3 {
  -webkit-font-smoothing: antialiased;
  font-size: 3.5rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 3.5rem;
  margin: -8px 0 64px -0.07em;
}

.typo-display2 {
  -webkit-font-smoothing: antialiased;
  font-size: 2.813rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 3rem;
  margin: -0.5rem 0 4rem -0.07em;
}

.typo-display1 {
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 2.5rem;
  margin: -0.5rem 0 4rem -0.07em;
}

.typo-headline {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 2rem;
  margin: -0.5rem 0 1rem -0.06em;

}

.typo-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2rem;
  margin: -0.5rem 0 1rem -0.05em;
}

.typo-subheading2 {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.75rem;
  margin: -0.5rem 0 1rem -0.06em;
}

.typo-subheading1 {
  -webkit-font-smoothing: antialiased;
  font-size: 0.938rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  margin: -0.313rem 0 0.813rem -0.06em;
}

.typo-body1 {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.25rem;

  &--margin {
    margin: 0 0 0.75rem 0;
  }
}

.typo-body2 {
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  margin: -0.25rem 0 0.75rem 0;
}

.typo-menu--subheader {
  -webkit-font-smoothing: antialiased;
  font-size: rem(14);
  letter-spacing: 0.04em;
  font-weight: 500;
  line-height: 1.5rem;
  margin: -0.25rem 0 0.75rem 0;
}

.typo-color {
  &--primary:not(:disabled) {
    color: var(--m3-sys-primary) !important;
  }

  &--warn {
    color: $color-warn !important;
  }

  &--gray {
    color: var(--gray-darkest) !important;
  }
}
.percentage-bar {
  border: 1px solid #ddd;
  width: 100%;
  height: 10px;
  box-shadow: 0px 0px 2px 1px #ddd;
  //   margin-bottom: 5px;
  display: flex;
  justify-content: space-between;

  span {
    height: 100%;
    // width: 60%;
    background: #1a4567;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }
}

.percentage-bar__percent {
  font-size: 10px !important;
  padding: 0 3px;
}

@media print {
  .percentage-bar span {
    background-color: #1a4567 !important;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    -webkit-print-color-adjust: exact;
  }
}

.fixed-assets__card{
    background-color: #fff;
    padding: 2em 2.5em;
}
.fixed-assets__card-title,
.fixed-assets__card-sub-title{
    font-size: 1.5em;
    font-weight: 400;
    margin-bottom: 1.5em;
}
.fixed-assets__card-sub-title{
    font-size: 1.1em;
}
.form-control-inline .mat-form-field{
    + .mat-form-field{
        margin-left: 1em;
    }
}
.fixed__btn--md button{
    min-width: 100px
}
.fixed-assets__button{
    mat-icon{
        margin-right: 1em;
    }
}
.fixed-assets__banner{
    margin-bottom: 2em;
    img{
        width: 100%;
    }
}
.fixed-assets__banner2{
    margin-bottom: 2em;
}
.fixed-assets__sep{
    display: flex;
    justify-content: center;
    &:after{
        content: "";
        height: 100%;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.1);;
    }
}
.fixed-assets__message {
    font-size: 1em;
    color: rgba(0, 0, 0, 0.87);
}
.form-control-flex{
    display: flex;
    flex-direction: column;
}
.fixed-assets__upload-control {
    height: 100%;
    border: 1px dashed rgba(0,0,0,.1);
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.fixed-assets__upload-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 230px;
}
[dir="ltr"] .rotate-ltr {
  transform: rotate(180deg);
}

.opacity-none {
  opacity: 0;
}

.opacity-6 {
  opacity: 0.6;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.screen-height {
  height: 100vh !important;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

.opacity-1 {
  opacity: 1;
}

.flex-1 {
  flex: 1;
}

[dir="ltr"] .text-left {
  text-align: right;
}

.fit-content {
  width: fit-content !important;
}

.height-fit-content {
  height: fit-content !important;
}

.no-print {
  @media print {
    display: none;
  }
}

.position-relative {
  position: relative !important;
}

.list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.d-non-xs {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.overlfow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden !important;
}

.min-width-unset {
  min-width: unset !important;
}

html[dir="rtl"] {
  .flip-rtl {
    transform: scaleX(-1);
  }

  .rotate-180.flip-rtl {
    transform: rotate(180deg) scaleX(-1);
  }
}

.width-auto {
  width: auto !important;
}

.line-height-md {
  line-height: 1.5;
}

.cursor-move {
  cursor: move;
}

.no-decoration {
  text-decoration: none;
}

.rotate-icon {
  transform: rotateX(180deg);
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.scroll-content {
  &::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-light-blue-grey);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-dark-grey-blue);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-azure);
  }
}

.no-border {
  border: none !important;
}

.text-nowrap {
  white-space: nowrap;
}

.disabled-link {
  pointer-events: none;
}

.text-editor {
  font-size: var(--font-size-s);
  line-height: 24px;

  p {
    margin-bottom: 1em;
  }
}

.details-cell {
  font-size: var(--font-size-s);
}

.details-cell__title {
  font-weight: var(--font-regular);
  color: var(--color-gray-dark-1);
  margin-bottom: var(--space-s);
  margin-top: 0;
  text-transform: capitalize;
}

.details-cell__text {
  color: var(--color-neutral-9);
  font-weight: var(--font-medium);
  line-height: 24px;
}

.drag-icon {
  cursor: move;
}

.table .cdk-drop-list-dragging tr:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-placeholder {
  min-width: 100%;
  background: var(--color-periwinkle);
  border: dotted 2px var(--color-cool-blue);
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.radio-toggle {
  min-width: 100px;
  font-size: var(--font-size-s);

  .mat-button-toggle-label-content {
    justify-content: center;
  }
}

.no-attachments {
  text-align: center;
  font-size: var(--font-size-s);
  margin: 0;
  padding: var(--space-base);
  font-weight: var(--font-regular);
  color: var(--color-warn);
}

.create-kpi__range {
  .radio-toggle {
    @include media-breakpoint-down(xl) {
      min-width: unset !important;
    }
  }
}

.img-responsive {
  max-width: 100%;
}



.product-placeholder {
  path {
    fill: var(--m3-sys-on-surface);
  }
}

.internal-block {
  z-index: 99 !important;
}

.mat-button-toggle-label-content {
  line-height: 36px;
}

.typo-label-large,
.side-nav__link,
body .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--m3-sys-on-surface) !important;
}

.mat-form-field-label {
  color: var(--m3-sys-on-surface) !important;
}

.background-transparent {
  background: transparent !important;
}
.background-full-opacity{
  background: var(--m3-sys-background-full-opacity) !important;
  color: var(--m3-sys-on-surface-variant) !important;
}

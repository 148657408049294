body {
  .mat-bottom-sheet-container {
    @extend %sys-bg;
    box-shadow: none;
    position: relative;
    padding: var(--space-xxl) var(--space-l) var(--space-m) var(--space-l);
    border-top-left-radius: var(--border-radius-rounded);
    border-top-right-radius: var(--border-radius-rounded);
  }
}

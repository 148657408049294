body {
  .mat-divider {
    border-top-color: var(--m3-sys-outline-variant);
  }

  .mat-divider.mat-divider-vertical {
    border-right-color: var(--m3-sys-outline-variant);
    height: 100%;
  }
}

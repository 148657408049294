.ag-theme-material .ag-checkbox-input-wrapper.ag-checked:after,
.ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate:after {
  color: $color-primary;
}

// .ag-theme-material .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button, .ag-theme-material .ag-rtl .ag-side-bar-left .ag-selected .ag-side-button-button {
//     border-left-color: $color-primary;
// }
:root {
  --ag-checkbox-checked-color: var(--m3-sys-primary);
  --ag-selected-tab-underline-color: var(--m3-sys-primary);
  --ag-range-selection-border-color: var(--m3-sys-primary);
}

.ag-watermark {
  display: none !important;
}

.ag-theme-material {
  color: var(--m3-sys-on-surface);
}

.ag-theme-material .ag-tool-panel-wrapper {
  width: 300px;
  border-inline-start: 1px solid var(--m3-sys-outline-variant);
  border-top: 0;
  background-color: var(--m3-sys-surface);

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.ag-theme-material .ag-side-buttons {
  color: var(--m3-sys-on-surface);
  background-color: var(--m3-sys-surface);
}

.ag-theme-material .ag-checkbox-input-wrapper:focus-within,
.ag-theme-material .ag-checkbox-input-wrapper:active {
  box-shadow: none !important;
  outline: none;
}

.ag-theme-material .ag-checkbox-input-wrapper input {
  cursor: pointer !important;
}

.ag-cell {
  display: inline-grid;
  align-items: center;
}

.ag-floating-bottom {
  overflow: auto !important;
}

.ag-theme-material button.ag-side-button-button {
  min-height: 9em !important;
}

.filter-panel__date-range {
  .md-drppicker {
    top: auto !important;
    left: unset !important;
    right: 3em !important;
    position: fixed !important;
  }

  &--center {
    .md-drppicker {
      top: 25vh !important;
    }
  }
}

[dir="rtl"].filter-panel__date-range .md-drppicker {
  left: 3em !important;
  right: unset !important;
}

.panel-wrapper-block {
  .ag-tool-panel-wrapper {
    display: block;
  }
}

.ag-theme-material .ag-rtl .ag-menu-option-icon,
.ag-theme-material .ag-rtl .ag-compact-menu-option-icon {
  padding-top: 16px;
}

body .ag-filter-view {

  .ag-header-cell-sortable:hover,
  .ag-header-cell:hover,
  .ag-header-group-cell:hover {
    background-color: var(--m3-sys-surface) !important;
    cursor: default;
  }

  .ag-header-cell-sortable::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
  }

}

.mobile-dir {
  display: inline-block;
  direction: ltr;
}

.ag-overlay-wrapper svg g {
  fill: currentColor;
}

.ag-cell-label-container,
.ag-header-cell-label {
  justify-content: start;
}

.ag-grid-number-column {

  .ag-cell-label-container,
  .ag-header-cell-label {
    justify-content: end;
  }

}

.ag-grid-center-column {

  .ag-cell-label-container,
  .ag-header-cell-label {
    justify-content: center;
  }

}

.ag-pinned-right-header,
.ag-pinned-left-header,
.ag-cell.ag-cell-first-right-pinned,
.ag-cell.ag-cell-last-left-pinned {
  border: none !important;
  line-height: initial;
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  border: none !important;
  overflow: hidden !important;
}

.ag-total {
  font-size: var(--font-size-s);
  font-weight: var(--font-light);
  line-height: 20px;
  color: var(--m3-sys-on-surface);
  margin-bottom: 0;
  margin-top: var(--space-s);
}

#{$light-theme} {
  .highlight-animation {
    animation: light-highlight-animation 2s ease-out;
  }

  @keyframes light-highlight-animation {
    0% {
      background-color: #fcfcff;
    }

    50% {
      background-color: #fcf3e1;
    }

    100% {
      background-color: #fcfcff;
    }
  }
}

#{$dark-theme} {
  .highlight-animation {
    animation: dark-highlight-animation 2s ease-out;
  }

  @keyframes dark-highlight-animation {
    0% {
      background-color: #1b1c1e;
    }

    50% {
      background-color: #5a4300;
    }

    100% {
      background-color: #1b1c1e;
    }
  }
}

.ag-dnd-ghost {
  max-height: 50px !important;
  max-width: max-content !important;
  background-color: var(--m3-sys-surface);
}

.ag-menu-separator {
  height: 1px;
}

.ag-theme-material .ag-cell .ag-checkbox-input-wrapper input,
.ag-theme-material .ag-header-cell .ag-checkbox-input-wrapper input {
  box-sizing: content-box;
  transform: translate(-36%, -30%);
  padding: 12px 22px;
}

.ag-theme-material .ag-rtl .ag-cell .ag-checkbox-input-wrapper input,
.ag-theme-material .ag-rtl .ag-header-cell .ag-checkbox-input-wrapper input {
  transform: translate(36%, -30%);
}
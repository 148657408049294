.lists-card {
  background-color: var(--m3-sys-surface);

  .mat-list-base {
    padding-top: 0;

    .mat-list-item,
    .mat-list-option {
      height: unset !important;
      padding: 0;
      // &+.mat-list-item,.mat-list-option{
      //   margin-top: var(--space-base) !important;
      // }
    }
  }

  .mat-list-option:hover,
  .mat-list-option:focus,
  .mat-nav-list .mat-list-item:hover,
  .mat-nav-list .mat-list-item:focus,
  .mat-action-list .mat-list-item:hover,
  .mat-action-list .mat-list-item:focus {
    background: var(--m3-sys-surface-variant);
  }

  .mat-list-base .mat-list-option,
  .mat-list-item-content {
    font-size: var(--font-size-base);
    color: var(--m3-sys-on-surface);
    font-weight: var(--font-regular);
  }

  .mat-list-base .mat-list-item .mat-list-item-content-reverse,
  .mat-list-base .mat-list-option .mat-list-item-content-reverse {
    padding: var(--space-s) var(--space-base);
  }

  .list-option__avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-inline-end: var(--space-base) !important;
    background-color: var(--m3-sys-primary-container);
    color: var(--m3-sys-on-surface);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-option__content {
    width: fit-content;
  }

  &--letter {

    .mat-list-base .mat-list-item .mat-list-text,
    .mat-list-base .mat-list-option .mat-list-text {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &--s {

    .mat-list-base .mat-list-item .mat-list-item-content-reverse,
    .mat-list-base .mat-list-option .mat-list-item-content-reverse {
      padding: var(--space-xs) var(--space-base);
    }
  }

  &--variants {

    .mat-list-item,
    .mat-list-option {
      white-space: pre-wrap;
    }

    .mat-list-item-content {
      display: grid !important;
      padding: 12px var(--space-base) !important;
    }

    &-avatar {
      .mat-list-item-content {
        padding: 12px var(--space-base) !important;
      }

      .mat-list-text {
        flex-direction: row !important;
        align-items: center;
      }
    }
  }

  &--start {

    .mat-mdc-list-item-content,
    .mat-mdc-list-text {
      align-items: start !important;
    }

    .mdc-list-item.mdc-list-item--with-two-lines {
      padding: var(--space-s) var(--space-base);
    }

    .mat-list-base .mat-list-item .mat-list-item-content-reverse,
    .mat-list-base .mat-list-option .mat-list-item-content-reverse {
      display: flex;
      align-items: center;
    }
  }

  .list-option__title {
    display: block;
  }

  .list-item__desc {
    font-size: var(--font-size-s) !important;
    color: var(--m3-sys-on-surface-variant);
    line-height: 1.5;
    white-space: pre-wrap;
  }

  // .list-item--start{
  //   .mat-list-item-content-reverse{
  //     align-items: flex-start !important;
  //   }
  // }
  // .list-item--center{
  //   .mat-list-text{
  //     align-items: center !important;
  //   }
  // }

  .mat-pseudo-checkbox {
    width: 1.125rem;
    height: 1.125rem;
  }

  .mat-pseudo-checkbox-checked::after {
    top: 0.15rem;
    left: 0.0625rem;
    width: 0.75rem;
    height: 0.1875rem;
  }

  .mat-pseudo-checkbox::after {
    color: var(--m3-sys-on-primary);
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate,
  .mat-accent .mat-pseudo-checkbox-checked,
  .mat-accent .mat-pseudo-checkbox-indeterminate {
    background-color: var(--m3-sys-primary);
  }

  .lists-card__image {
    width: 56px;
    height: 56px;
    object-fit: cover;
  }
}

.lists-card--image {

  .mat-list-item-content {
    padding-inline-start: 0 !important;
  }

  .lists-card__image {
    width: 114px;
    height: 64px;
  }
}

.lists-card--reverse.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content-reverse,
.lists-card--reverse .mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-item-content-reverse {
  flex-direction: row !important;
}

.lists-card--reverse.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.lists-card--reverse .mat-list-base .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.lists-card--reverse .mat-list-base .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.lists-card--reverse .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
  padding-left: 16px !important;
  padding-right: 0 !important;
}

.list-card__wrapper {
  display: flex;
  padding: 12px var(--space-base);
}

body {
  .mdc-list-item.mdc-list-item--with-one-line {
    display: flex;
    align-items: center;
  }

  .mat-mdc-list-item-unscoped-content {
    display: flex;
    align-items: center;
  }

  .lists-card--image,
  .lists-card--reverse {
    .mdc-list-item__content {
      margin-inline-end: 0;
    }
  }

  .list-option__text {
    color: var(--m3-sys-on-surface);
  }

  .mdc-dialog__container .mdc-list-item.mdc-list-item--with-one-line {
    padding: var(--space-s) var(--space-none);
    height: auto;
    border-bottom: 1px solid var(--m3-sys-outline-variant);

    &:last-child {
      border-bottom: 0;
    }
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    color: var(--m3-sys-on-primary);
  }
}

body .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
body .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
body .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
  background-color: var(--m3-sys-primary) !important;
  border-color: var(--m3-sys-primary) !important;
}

body {
  .mdc-list {
    padding: 0;
  }

  .mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
    margin: 0;
  }

  .mat-mdc-list-option .mdc-checkbox {
    padding: 0;
    vertical-align: unset;
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
  }

  .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__end {
    display: flex;
  }

  .mdc-list-item.mdc-list-item--with-one-line {
    padding: var(--space-s) var(--space-base);
    height: auto;
  }

  .mdc-list-item.mdc-list-item--with-two-lines {
    height: auto;
  }

  .mdc-list-item__primary-text {
    color: var(--m3-sys-on-surface-variant);
  }

  .mdc-list-item:hover .mdc-list-item__primary-text {
    color: var(--m3-sys-on-surface-variant);
  }

  .lists-card--start .mdc-list-item.mdc-list-item--with-two-lines:hover {
    background-color: var(--m3-sys-surface-variant);
  }

  .mdc-list-item--with-two-lines .mdc-list-item__primary-text,
  .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
    margin-bottom: 0;
    display: flex;
  }

  .mat-mdc-list-item.mdc-list-item--with-three-lines .mat-mdc-list-item-unscoped-content.mdc-list-item__secondary-text {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .mdc-list-item--with-two-lines .mdc-list-item__content,
  .mdc-list-item--with-three-lines .mdc-list-item__content {
    align-self: center;
  }

  .mdc-list-item--with-trailing-checkbox.mdc-list-item {
    padding-right: var(--space-base);
    padding: var(--space-s) var(--space-base);
  }

  .mdc-list-item.mdc-list-item--with-three-lines {
    height: auto;
    padding: var(--space-s) var(--space-base);
  }

  .lists-card--variants-avatar {
    .mdc-list-item {
      padding: 12px var(--space-base) !important;
    }
  }


  .lists-card--variants {
    .mdc-list-item {
      padding: 12px var(--space-base);
    }
  }

  .mdc-list-item--with-three-lines .mdc-list-item__secondary-text {
    line-height: normal;
  }

  .mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
  .mdc-list-item--with-three-lines .mdc-list-item__primary-text::before {
    content: none;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--m3-sys-on-surface-variant);
  }

  .mdc-list-item__primary-text {
    line-height: normal;
    font-weight: 400;
  }

  .mdc-list-item__content {
    margin-inline-end: var(--space-base);
    align-items: center;
  }

  .mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__end {
    align-self: self-start;
  }

  .mat-mdc-list {
    .icon {
      fill: var(--m3-sys-on-surface-variant);
    }
  }

  .lists-card--reverse {
    .mdc-list-item {
      flex-direction: row-reverse;
    }

    .mdc-list-item__content {
      padding-inline-start: var(--space-base);
    }
  }
}

.list-item__details {
  color: var(--m3-sys-on-surface);
}
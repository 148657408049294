body {
  .mat-mdc-snack-bar-container {
    background-color: var(--m3-sys-inverse-surface);
    max-width: 100vw;
    // padding-inline-end: var(--space-s);
    border-radius: 4px;

    .mat-icon {
      color: var(--m3-sys-inverse-on-surface);
    }

    .mat-button,
    .mat-icon-button,
    .mat-stroked-button {
      color: var(--m3-sys-inverse-primary);
      font-size: var(--typo-title-small);
    }


    .mat-mdc-button {
      padding: 0 12px;
      height: 40px;
      min-width: unset;
      letter-spacing: normal;
    }
  }

  .snackbar-wrapper {
    max-width: 345px;
  }

  .snackbar-content {
    color: var(--m3-sys-inverse-on-surface);
    font-size: var(--font-size-s);
    line-height: 20px;
    margin-bottom: var(--space-s);
  }

  .mat-mdc-snack-bar-container .snackbar-content .mat-icon {
    color: var(--m3-sys-inverse-on-surface);
  }

  .mat-mdc-snack-bar-label  {
    color: var(--m3-sys-inverse-on-surface);
    @extend .typo-body-medium;
    text-transform: unset !important;
  }

  .snackbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .snackbar__title {
    color: var(--m3-sys-inverse-on-surface);
    font-size: var(--typo-title-small);
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__label {
    display: block;
    // padding-bottom: 0;
    margin: 0;
  }

  .mdc-snackbar__action {
    .icon {
      fill: var(--m3-sys-inverse-primary);
    }
  }

  .mdc-snackbar__actions {
    // margin-bottom: 14px;
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: var(--m3-sys-inverse-on-surface);
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: var(--m3-sys-inverse-surface);
  }

  .mat-mdc-snack-bar-action {
    .mdc-button__label {
      white-space: nowrap;
      width: 100%;
      color: inherit;
    }
  }
}

/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.

// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.

$primary-color: (
  50 : #e0ecf3,
  100 : #b3d0e2,
  200 : #80b1ce,
  300 : #4d91ba,
  400 : #267aac,
  500 : var(--m3-sys-primary),
  600 : #005a95,
  700 : #00508b,
  800 : #004681,
  900 : #00346f,
  A100 : #9ec3ff,
  A200 : #6ba4ff,
  A400 : #3885ff,
  A700 : #1f75ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #771919,
      400 : #ffffff,
      500 : var(--m3-sys-on-primary),
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$accent-color: (
  50 : #e0edf2,
  100 : #b3d1dd,
  200 : #80b2c7,
  300 : #4d93b1,
  400 : #267ca0,
  500 : var(--m3-sys-secondary),
  600 : #005d87,
  700 : #00537c,
  800 : #004972,
  900 : #003760,
  A100 : #90c8ff,
  A200 : #5daeff,
  A400 : #2a95ff,
  A700 : #1188ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : var(--m3-sys-on-secondary),
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )

);

$warn-color: (
  50 : #f7e4e4,
  100 : #eababa,
  200 : #dd8d8d,
  300 : #cf5f5f,
  400 : #c43c3c,
  500 : var(--m3-sys-error),
  600 : #b31717,
  700 : #ab1313,
  800 : #a30f0f,
  900 : #940808,
  A100 : #ffc1c1,
  A200 : #ff8e8e,
  A400 : #ff5b5b,
  A700 : #ff4141,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : var(--m3-sys-on-error),
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$app-primary-color: mat.m2-define-palette($primary-color);
$app-accent-color: mat.m2-define-palette($accent-color);
$app-warn-color: mat.m2-define-palette($warn-color);

$app-typography: mat.m2-define-typography-config(
  $font-family: var(--font-family),
  $headline-1: mat.m2-define-typography-level(var(--typo-display-large), calc(1.2 * var(--typo-display-large)), var(--font-semi-bold)),
  $headline-2 : mat.m2-define-typography-level(var(--typo-display-medium), calc(1.2 * var(--typo-display-medium)), var(--font-regular)),
  $headline-3: mat.m2-define-typography-level(var(--typo-display-small), calc(1.2 * var(--typo-display-small)), var(--font-regular)),
  $headline-4: mat.m2-define-typography-level(var(--typo-headline-large), calc(1.2 * var(--typo-headline-large)), var(--font-regular)),
  $headline-5: mat.m2-define-typography-level(var(--typo-headline-medium), calc(1.2 * var(--typo-headline-medium)), var(--font-regular)),
  $headline-6: mat.m2-define-typography-level(var(--typo-headline-small), calc(1.2 * var(--typo-headline-small)), var(--font-regular)),
  $subtitle-1: mat.m2-define-typography-level(var(--typo-title-large), 28px, var(--font-regular)),
  $subtitle-2: mat.m2-define-typography-level(var(--typo-title-medium), 24px, var(--font-regular)),
  $body-1: mat.m2-define-typography-level(var(--typo-title-small), 24px, var(--font-regular)),
  $body-2: mat.m2-define-typography-level(var(--typo-body-large), 20px, var(--font-regular)),
  $caption: mat.m2-define-typography-level(var(--typo-label-small), 20px, var(--font-regular)),
  $button: mat.m2-define-typography-level(var(--typo-body-medium), var(--font-size-m), var(--font-medium)),
 
);


$app-theme: mat.m2-define-light-theme((
  color: (
    primary: $app-primary-color,
    accent: $app-accent-color,
    warn: $app-warn-color,
  ),
 typography: $app-typography,
));
@include mat.all-component-themes($app-theme);


html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  --mdc-radio-state-layer-size: 20px !important;
}

.chat-box {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 100;
  display: flex;
  /* fix issue in mobile  */
  @include breakpoint(isCommon){
      transform: translate(77vw, 82vh) !important;
  }

  &:hover{
    .chat-box__handle {
      opacity: 1;
    }
  }
}
[dir="rtl"]{
  .chat-box {
    right: auto;
    left: 15px;
  }
}

.chat-box__handle {
  color: var(--m3-sys-on-surface-variant);
  cursor: move;
  bottom: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

#fc_frame,
#fc_push_frame {
  position: fixed !important;
  bottom: 60px !important;
  right: 20px !important;
  left: auto !important;
}

div.chat-fc-form-outer {
  position: fixed !important;
  bottom: 60px !important;
  right: 20px !important;
  left: auto !important;
}

.network-status.offline {
  position: fixed;
  top: 25%;
  right: -55px;
  // height: 18px;
  background: var(--m3-sys-error);
  width: auto;
  z-index: 99999999999;
  padding: 0 16px;
  text-align: center;
  transform: rotate(-90deg);
  //   visibility: hidden;
  color: var(--m3-sys-on-error);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  opacity: 1;
  line-height: 1.7;
  font-weight: 400;
  @include transition();
  &:hover {
    opacity: 1;
  }
}

[dir="rtl"] .network-status.offline {
  right: auto;
  left: -70px;
  transform: rotate(90deg);
}
body{
  .intercom-namespace .intercom-messenger-frame{
    height: min(700px, 100% - 160px);
    max-height: 700px;
    transform-origin: center center;
    right: 20px;
    left: auto;
    top: 72px;
  }
  &[data-intercom-origin="origin-1"]{
    .intercom-namespace .intercom-messenger-frame{
      transform-origin: left top;
    }
  }
  &[data-intercom-origin="origin-2"]{
    .intercom-namespace .intercom-messenger-frame{
      transform-origin: left bottom;
    }
  }
  &[data-intercom-origin="origin-3"]{
    .intercom-namespace .intercom-messenger-frame{
      transform-origin: right top;
    }
  }
  &[data-intercom-origin="origin-4"]{
    .intercom-namespace .intercom-messenger-frame{
      transform-origin: right bottom;
    }
  }

}


[dir="rtl"]  .intercom-namespace .intercom-messenger-frame{
  left: 20px !important;
  right: auto !important;
}

.mat-flat-button.mat-primary {
  .mat-icon{
    color: var(--m3-sys-on-pimary);
    margin-inline-end: var(--space-s);

  }
  .mat-icon & [dir=rtl]{
    line-height: 1.2;
  }
}
[dir="rtl"] {
  .mat-flat-button.mat-primary {
    .mat-icon{
      line-height: 1.2;
      margin-inline-end:auto;
      margin-inline-start: var(--space-s);

    }

  }
}

.samples__header{
  background-color: var(--color-surface-5);
  padding: 5em 2em 2em;
  border-radius: 2em 2em 0 0;
}
.samples__body{
  background-color: var(--m3-sys-surface);
  color: var(--m3-sys-on-surface);
  color: var(--m3-sys-on-primary);
  padding: 2em 0;
  border-radius: 0 0 1em 1em;
}

.samples-panel{
  background-color: var(--m3-sys-surface);
  color: var(--m3-sys-on-surface);
  border: 1px solid var(--m3-sys-inverse-on-surface);
  border-radius: var(--border-radius-m);
  padding: 2em;
}

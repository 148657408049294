// styling material buttons
body {
  --btn-padding: 0 24px;

  // state layer
  .mat-mdc-button-base,
  .icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    position: relative;
    overflow: visible;

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: background-color .3s ease-in-out;
    }
  }

  .mat-calendar-controls .icon-button::after {
    width: auto;
    height: auto;
  }

  .mat-mdc-button-base,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button {
    padding: var(--btn-padding);
    min-height: 40px;
    line-height: 40px;
    height: auto;
    border-radius: var(--border-radius-rounded);
    transition: box-shadow .2s ease-in-out, background-color .2s ease-in-out;

    [dir="rtl"] & {
      line-height: 35px;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    .button__icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      margin-inline-end: 10px;
      fill: var(--m3-sys-on-primary);
    }
  }

  .mat-stroked-button,
  .mat-flat-button,
  .mat-raised-button {
    .button__icon {
      margin-inline-start: -8px;
      margin-inline-end: 10px;
    }
  }

  // filled buttons
  .mat-flat-button:not(.mat-flat-button.mat-button-disabled) {
    &:hover {
      box-shadow: var(--m3-sys-elevation-1);

      &:after {
        background-color: var(--m3-state-layers-on-primary-opacity-08);
      }
    }
  }

  // outlined buttons
  .mat-stroked-button:not(.mat-stroked-button.mat-button-disabled) {
    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    &:focus {
      border-color: currentColor;
    }
  }

  // text buttons
  .mat-mdc-button {
    --btn-padding: 0 12px;
    display: inline-flex;
  }

  .mat-mdc-button:not(.mat-mdc-stroked-button.mat-mdc-button-disabled) {
    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }
  }

  // elevated buttons
  .mat-raised-button:not(.mat-raised-button.mat-button-disabled, .mat-warn) {
    background-color: var(--color-surface-1);
    color: var(--m3-sys-primary);
    box-shadow: var(--m3-sys-elevation-1);

    &:active:not([class*=mat-elevation-z]) {
      box-shadow: var(--m3-sys-elevation-1);
    }

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
      box-shadow: var(--m3-sys-elevation-2);
    }
  }

  .mat-raised-button.mat-warn:not(.mat-raised-button.mat-button-disabled) {
    color: var(--m3-sys-on-surface);
  }

  // tertiary buttons
  .button--tertiary {
    color: var(--m3-sys-tertiary);
  }

  .mat-flat-button.button--tertiary,
  .mat-raised-button.button--tertiary {
    background-color: var(--m3-sys-tertiary);
    color: var(--m3-sys-on-tertiary);
  }

  // tonal buttons
  .button--tonal,
  [class *=mat-mdc-fab].button--tonal {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-secondary-container);
  }

  // stroked buttons
  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: var(--m3-sys-outline);
  }

  .icon-button {
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  // disabled buttons
  .mat-button.mat-primary.mat-button-disabled,
  .mat-button.mat-accent.mat-button-disabled,
  .mat-button.mat-warn.mat-button-disabled,
  .mat-button.mat-button-disabled.mat-button-disabled,
  .icon-button.mat-primary.mat-button-disabled,
  .icon-button.mat-accent.mat-button-disabled,
  .icon-button.mat-warn.mat-button-disabled,
  .icon-button.mat-button-disabled.mat-button-disabled,
  .mat-stroked-button.mat-primary.mat-button-disabled,
  .mat-stroked-button.mat-accent.mat-button-disabled,
  .mat-stroked-button.mat-warn.mat-button-disabled,
  .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    color: var(--m3-sys-on-surface);
    border-color: var(--m3-state-layers-on-surface-opacity-12);

    .mat-button-wrapper {
      opacity: .38;
    }
  }

  .mat-flat-button.mat-primary.mat-button-disabled,
  .mat-flat-button.mat-accent.mat-button-disabled,
  .mat-flat-button.mat-warn.mat-button-disabled,
  .mat-flat-button.mat-button-disabled.mat-button-disabled,
  .mat-raised-button.mat-primary.mat-button-disabled,
  .mat-raised-button.mat-accent.mat-button-disabled,
  .mat-raised-button.mat-warn.mat-button-disabled,
  .mat-raised-button.mat-button-disabled.mat-button-disabled,
  .mat-mdc-fab.mat-primary.mat-button-disabled,
  .mat-mdc-fab.mat-accent.mat-button-disabled,
  .mat-mdc-fab.mat-warn.mat-button-disabled,
  .mat-mdc-fab.mat-button-disabled.mat-button-disabled,
  .mat-mdc-mini-fab.mat-primary.mat-button-disabled,
  .mat-mdc-mini-fab.mat-accent.mat-button-disabled,
  .mat-mdc-mini-fab.mat-warn.mat-button-disabled,
  .mat-mdc-mini-fab.mat-button-disabled.mat-button-disabled {
    background-color: var(--m3-state-layers-on-surface-variant-opacity-12);
    color: var(--m3-sys-on-surface);

    .mat-button-wrapper {
      opacity: .38;
    }
  }

  .mat-button.mat-button-disabled {
    color: var(--m3-sys-on-surface);
  }

  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    border-radius: var(--border-radius-rounded);
    border-color: var(--m3-sys-outline);
  }

  .mat-button-toggle {
    background-color: transparent;

    .icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }
  }

  .mat-button-toggle-button {
    padding: 0 24px;
    height: 100%;

    .mat-button-toggle-label-content {
      span {
        line-height: 40px;
      }
    }

  }

  .mat-button-toggle.mat-menu-trigger {
    .mat-button-toggle-button {
      padding: 0 14px;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
    @extend .typo-label-large;
    color: var(--m3-sys-on-surface);
  }

  .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
    background-color: transparent;

    .mat-button-toggle-button {
      opacity: 0.38;
    }
  }

  .mat-button-toggle-checked {
    background-color: var(--m3-sys-secondary-container);

    .mat-button-toggle-label-content {
      color: var(--m3-sys-on-secondary-container);
      font-family: var(--material-font);
      display: flex;
      align-items: center;

      // &::before {
      //   content: "done";
      //   font-family: var(--material-font);
      //   font-size: 20px;
      //   text-transform: none;
      //   color: inherit;
      //   padding-inline-end: var(--space-s);
      // }
    }

  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-color: var(--m3-sys-outline);
  }

  .mat-mdc-mini-fab {
    border-radius: 12px;
    width: 40px;
    height: 40px;
  }

  .mat-mdc-fab {
    border-radius: 16px;
    width: 56px;
    height: 56px;
  }

  .button-lg-fab {
    border-radius: 28px;
    width: 96px;
    height: 96px;

    .icon {
      width: 36px;
      height: 36px;
      font-size: 36px;
      fill: var(--m3-sys-on-primary-container);
    }
  }

  .button-extended-fab {
    border-radius: 16px;
    width: auto;
    height: 56px;
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 16px;
    padding-inline-end: 20px;

    .icon {
      margin-inline-end: var(--space-s);
    }
  }

  .button--surface {
    background: var(--color-surface-3);
    @extend %sys-bg;
    position: relative;
    color: var(--m3-sys-primary);

    .icon {
      fill: var(--m3-sys-primary) !important;
    }
  }

  .button-extended-fab.mat-primary,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    background-color: var(--m3-sys-primary-container);
    color: var(--m3-sys-on-primary-container);

    .icon {
      fill: var(--m3-sys-on-primary-container);
    }
  }

  .button-extended-fab.mat-accent,
  .mat-mdc-fab.mat-accent,
  .mat-mdc-mini-fab.mat-accent {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-secondary-container);

    .icon {
      fill: var(--m3-sys-on-secondary-container);
    }
  }

  .button-extended-fab.button--tertiary,
  .mat-mdc-fab.button--tertiary,
  .mat-mdc-mini-fab.button--tertiary {
    background-color: var(--m3-sys-tertiary-container);
    color: var(--m3-sys-on-tertiary-container);

    .icon {
      fill: var(--m3-sys-on-tertiary-container);
    }
  }

  .mat-mdc-fab:not([class*=mat-elevation-z]),
  .mat-mdc-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: var(--m3-sys-elevation-3);

    &:hover {
      box-shadow: var(--m3-sys-elevation-4);
    }
  }

  .mat-mdc-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
  .mat-mdc-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
    box-shadow: var(--m3-sys-elevation-3);
  }

  .button--block {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .base-button-focused {
    background-color: #e6e6e6;
  }

  .primary-button-focused {
    background-color: #e6f2ff;
  }

  .warn-button-focused {
    background-color: #fee8e7;
  }

  .icon-btn__bg {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .rounded-btn {
    border-radius: 24px !important;
  }
}

.typo-label-large,
.side-nav__link,
body .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

a {
  color: var(--m3-sys-primary);

  &:hover {
    color: var(--m3-sys-primary);
  }
}

a[disabled] {
  pointer-events: none !important;
}


body .mat-button-toggle .icon {
  color: var(--m3-sys-on-primary);
}

.button--icon {
  .icon {
    margin-inline-end: var(--space-base);
  }
}

.button-group {
  display: flex;

  @include media-breakpoint-down(sm) {
    margin-top: var(--space-m);
  }

  .actions-btn+.actions-btn {
    margin-inline-start: var(--space-m);
  }

  &--not-check {
    .mat-button-toggle-checked .mat-button-toggle-label-content::before {
      display: none;
    }
  }
}

.button-wrapper--block {
  .mat-button-wrapper {
    display: block !important;
  }
}

body {
  .button-icon {
    .icon {
      fill: var(--m3-sys-on-surface-variant);
    }

    &::after {
      border-radius: 100%;
    }

    &:hover {
      background-color: var(--m3-state-layers-on-surface-opacity-08);
    }

    &:focus {
      background-color: var(--m3-state-layers-on-surface-opacity-12);
    }
  }

  .button-icon.mat-primary {
    background-color: var(--m3-sys-primary);

    .icon {
      fill: var(--m3-sys-on-primary);
    }

    &:hover {
      &::after {
        background-color: var(--m3-state-layers-on-primary-opacity-08);
      }
    }

    &:focus {
      &::after {
        background-color: var(--m3-state-layers-on-primary-opacity-12);
      }
    }
  }

  .button-icon.mat-accent {
    background-color: var(--m3-sys-secondary-container);

    .icon {
      fill: var(--m3-sys-on-secondary-container);
    }

    &:hover {
      &::after {
        background-color: var(--m3-state-layers-on-secondary-container-opacity-08);
      }
    }

    &:focus {
      &::after {
        background-color: var(--m3-state-layers-on-secondary-container-opacity-12);
      }
    }
  }

  .button-icon.button-icon-outline {
    border: 1px solid var(--m3-sys-outline);

    .icon {
      fill: var(--m3-sys-on-surface-variant);
    }

    &:hover {
      &::after {
        background-color: var(--m3-state-layers-on-surface-variant-opacity-08);
      }
    }

    &:focus {
      &::after {
        background-color: var(--m3-state-layers-on-surface-variant-opacity-12);
      }
    }
  }

  .button-icon--dark {
    background-color: var(--m3-sys-inverse-surface);

    .icon {
      fill: var(--m3-sys-inverse-on-surface);
    }

    &:hover {
      background-color: var(--m3-sys-inverse-surface);

      &::after {
        background-color: var(--m3-state-layers-inverse-on-surface-opacity-08);
      }
    }

    &:focus {
      background-color: var(--m3-sys-inverse-surface);

      &::after {
        background-color: var(--m3-state-layers-inverse-on-surface-opacity-12);
      }
    }
  }

  .button-icon--dark.mat-button-disabled {
    background-color: var(--m3-state-layers-on-surface-opacity-12);

    .icon {
      fill: var(--m3-sys-on-surface);
    }

    &:hover {
      background-color: var(--m3-state-layers-on-surface-opacity-12);

      &::after {
        background-color: var(--m3-state-layers-on-surface-opacity-12);
      }
    }

    &:focus {
      background-color: var(--m3-state-layers-on-surface-opacity-12);

      &::after {
        background-color: var(--m3-state-layers-on-surface-opacity-12);
      }
    }

    .button--soft {
      border-radius: var(--border-radius-soft) !important;
    }

    body {
      .filter__button {
        margin-inline-end: 12px;

        .mat-badge-content {
          background: var(--m3-sys-light-primary);
          position: initial;
        }

        .mat-badge {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &[aria-expanded="true"] {
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
          position: relative;
          z-index: 999;
        }
      }

      .filter,
      .filter__dropdown {
        .filter__button {
          border: 1px solid transparent;
        }

        .filter__button[aria-expanded=true] {
          box-shadow: var(--m3-sys-elevation-3);
          border: 1px solid var(--m3-sys-primary);
          background-color: var(--m3-sys-surface);
        }
      }

      .filter__dropdown-button[aria-expanded=true] {
        box-shadow: var(--m3-sys-elevation-3);
        border: 1px solid var(--m3-sys-primary);
        background-color: var(--m3-sys-surface);
      }
    }
  }
}

body {

  // buttons filled
  .mat-mdc-unelevated-button.mat-primary {
    background-color: var(--m3-sys-primary);
    color: var(--m3-sys-on-primary);
  }

  .mat-mdc-unelevated-button.mat-accent {
    background-color: var(--m3-sys-secondary);
    color: var(--m3-sys-on-secondary);
  }

  .mat-mdc-unelevated-button.mat-warn {
    background-color: var(--m3-sys-error);
    color: var(--m3-sys-on-error);
  }

  .mat-mdc-unelevated-button.btn-success .mdc-button__label {
    color: var(--color-white);
  }


  .mdc-button--unelevated {
    &:hover {
      box-shadow: var(--m3-sys-elevation-1);

      &:after {
        background-color: var(--m3-state-layers-on-primary-opacity-08);
      }
    }
  }

  // buttons outlined

  button.mat-mdc-unelevated-button[disabled],
  button.mat-mdc-unelevated-button.mat-mdc-button-disabled {
    background-color: var(--m3-state-layers-on-surface-variant-opacity-12) !important;
    color: var(--m3-sys-on-surface) !important;

    .button__icon {
      fill: var(--m3-sys-on-surface);
    }

    .mdc-button__label {
      opacity: .38;
    }
  }

  .mat-mdc-outlined-button:disabled {
    .icon {
      opacity: .38;
    }
  }

  .mat-mdc-outlined-button:not(:disabled) {
    border-color: var(--m3-sys-outline);
  }

  .mat-mdc-outlined-button[disabled],
  .mat-mdc-outlined-button.mat-mdc-button-disabled {
    color: var(--m3-sys-on-surface);
    border-color: var(--m3-state-layers-on-surface-opacity-12);

    .mdc-button__label {
      opacity: .38;
    }

    .button__icon {
      fill: var(--m3-sys-on-surface);
    }
  }

  .mat-mdc-outlined-button.mat-primary {
    color: var(--m3-sys-primary);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    &:focus {
      color: var(--m3-sys-primary);
      border-color: var(--m3-sys-primary);
    }


    .button__icon {
      fill: var(--m3-sys-primary);
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    color: var(--m3-sys-secondary);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    &:focus {
      color: var(--m3-sys-secondary);
      border-color: var(--m3-sys-secondary);
    }

    .button__icon {
      fill: var(--m3-sys-secondary);
    }
  }

  .mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before {
    background-color: var(--m3-state-layers-primary-opacity-08);
  }

  .mat-mdc-outlined-button.mat-warn {
    color: var(--m3-sys-error);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    &:focus {
      color: var(--m3-sys-error);
      border-color: var(--m3-sys-error);
    }

    .button__icon {
      fill: var(--m3-sys-error);
    }
  }

  // text button
  .mat-mdc-button.mat-primary {
    color: var(--m3-sys-primary);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    .button__icon {
      fill: var(--m3-sys-primary);
    }
  }

  .mat-mdc-button.mat-accent {
    color: var(--m3-sys-secondary);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    .button__icon {
      fill: var(--m3-sys-secondary);
    }
  }

  .mat-mdc-button.mat-warn {
    color: var(--m3-sys-error);

    &:hover {
      background-color: var(--m3-state-layers-primary-opacity-08);
    }

    .button__icon {
      fill: var(--m3-sys-error);
    }
  }

  .mat-mdc-button[disabled],
  .mat-mdc-button.mat-mdc-button-disabled {
    color: var(--m3-sys-on-surface);
    opacity: .38;
  }

  // Other buttons

  .button--tonal.mat-mdc-unelevated-button:not(:disabled) {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-secondary-container);

    .button__icon {
      fill: var(--m3-sys-on-secondary-container);
    }
  }

  // segmented buttons
  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-primary);

    mat-pseudo-checkbox {
      display: none;
    }
  }

  .button-group--not-check {
    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      background-color: var(--m3-sys-primary);
      color: var(--m3-sys-on-primary);
    }
  }

  // fab buttons
  .mat-mdc-fab:not(.mdc-fab--extended),
  .mat-mdc-mini-fab:not(.mdc-fab--extended) {
    box-shadow: var(--m3-sys-elevation-3);
    border-radius: 12px;
  }

  .mat-mdc-mini-fab {
    border-radius: 12px;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .mat-mdc-fab {
    border-radius: 16px;
    width: 56px;
    height: 56px;
    padding: 0;
  }

  .mat-mdc-fab.button-lg-fab {
    border-radius: 28px;
    width: 96px;
    height: 96px;
    padding: 0;
  }

  .mat-mdc-fab.button-extended-fab {
    border-radius: 16px;
    width: auto;
    height: 56px;
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 16px;
    padding-inline-end: 20px;
  }

  // snackbar button
  .mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: var(--m3-sys-inverse-primary);
    padding: 0 12px;
  }

  .mdc-button__label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    font-size: inherit;
  }

  .mat-mdc-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  // .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  //   width: 18px;
  //   height: 18px;
  //   --mdc-icon-button-state-layer-size: 20px;
  // }

  .mat-mdc-fab:not(.mdc-fab--extended) .mdc-fab__ripple,
  .mat-mdc-mini-fab:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 16px;
  }

  .button-elevated {
    .icon {
      fill: var(--mdc-filled-button-label-text-color);
    }
  }

  .mat-mdc-button.mat-accent:active {
    --mat-text-button-state-layer-color: transparent;
  }
}

body {
  .mat-mdc-menu-panel {
    .mat-primary.mdc-button:not(:disabled) {
      color: var(--m3-sys-primary);
    }
  }

  .mat-mdc-outlined-button.mat-accent {
    --mat-outlined-button-ripple-color: transparent;
  }

  .mat-mdc-icon-button[disabled],
  .mat-mdc-icon-button.mat-mdc-button-disabled {
    color: var(--m3-sys-outline);
  }
}


.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 0px;
}

body .mat-button-toggle-group .mat-button-group--drop-down .mat-button-toggle-button {
  padding: 0 14px;
}

.button-group--primary .mat-button-toggle {
  background-color: var(--m3-sys-primary);
  color: var(--m3-sys-on-primary);
}

body .mat-mdc-unelevated-button.mat-primary {
  background-color: var(--m3-sys-primary) !important;
  color: var(--m3-sys-on-primary);
}

.mat-unthemed .mdc-button__label {
  color: var(--m3-sys-on-surface);

  icon:not([svgfill]) .icon {
    fill: var(--m3-sys-on-surface);
  }
}




.button--block {
  width: 100%;
}

.base-button-focused {
  background-color: #e6e6e6;
}

.primary-button-focused {
  background-color: #e6f2ff;
}

.warn-button-focused {
  background-color: #fee8e7;
}

.mat-icon-btn__bg {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.rounded-btn {
  border-radius: 24px !important;
}

.button--rounded {
  border-radius: 100px !important;
}

.button--md {
  padding: 10px 20px !important;
  line-height: normal !important;
}

.mat-mdc-icon-button[disabled],
.mat-mdc-icon-button.mat-mdc-button-disabled {
  pointer-events: auto !important;
}

.mat-mdc-unelevated-button:not(:disabled) {
  .mdc-button__label {
    color: var(--color-black);
  }
}

.button--soft {
  border-radius: var(--border-radius-soft) !important;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ws-pre-line {
  white-space: pre-line;
}
.pull-left {
  float: left;
}
.pull-right{
  float: right;
}
 .background-light{
    background: var(--m3-sys-on-primary) !important;
    background-color: var(--m3-sys-on-primary) !important;
 }

@use "sass:map";

:root {
  --font-family: 'Roboto', sans-serif;
  --material-font: "Material Icons";

  // app
  --body-font-family: var(--font-family);
  --body-font-size: var(--typo-body-large);
  --body-font-weight: var(--font-regular);

  // content
  --content-end-padding: var(--space-base);
  --content-start-padding: var(--space-base);
  --content-y-padding: var(--space-m);

  // header
  --header-height: 60px;
  --header-bg: #292a35;
  --header-y-padding: var(--space-m);
  --header-padding: var(--header-y-padding) var(--content-x-padding);
  --bottom-bar-height: 80px;

  // logo
  --logo-width: 125px;
  --logo-height: 25px;
  --logo-emblem: #0091EA;
  --logo-text: #404041;

  // side nav
  --nav-width: 0;
  --actions-width: 0;
  --collapsed-nav-width: 115px;
  --expanded-nav-width: 285px;
  --expanded-actions-width: 100px;

  --side-nav-width: 64px;
  --side-nav-transition: cubic-bezier(0.25, 0.8, 0.25, 1);
  --side-nav-duration: 0.2s;

  @include media-breakpoint-up(sm) {
    --header-height: 90px;
    --content-end-padding: var(--space-m);
    --content-start-padding: var(--space-l);
  }
}

#{$dark-theme} {
  --logo-text: var(--m3-white);
}

[dir="rtl"] {
  --font-family: 'hvtar', sans-serif;
  --body-font-family: 'hvtar', sans-serif;
  direction: rtl;
}

::selection {
  color: var(--m3-sys-primary);
  background: var(--m3-sys-surface-variant);
}

$colors: (
  'm3-white': #ffffff,
  'm3-black': #000000,
  'm3-source-Seed': #6750a4,
  'm3-source-Primary': #6750a4,
  'm3-source-Secondary': #625b71,
  'm3-source-Tertiary': #7d5260,
  'm3-source-Neutral': #605d62,
  'm3-source-Neutral-Variant': #605d66,
  'm3-source-Error': #b3261e,
  'm3-source-seed': #01639e,
  'm3-source-primary': #01639e,
  'm3-source-secondary': #37a2dc,
  'm3-source-tertiary': #ffc400,
  'm3-key-colors-primary': #01639e,
  'm3-key-colors-secondary': #37a2dc,
  'm3-key-colors-tertiary': #ffc400,
  'm3-key-colors-error': #ba1a1a,
  'm3-key-colors-neutral': #5d5e61,
  'm3-key-colors-neutral-variant': #5a5f66,
  'm3-sys-light-primary': #00629d,
  'm3-sys-light-on-primary': #ffffff,
  'm3-sys-light-primary-container': #cfe5ff,
  'm3-sys-light-on-primary-container': #001d34,
  'm3-sys-light-secondary': #00658f,
  'm3-sys-light-on-secondary': #ffffff,
  'm3-sys-light-secondary-container': #c8e6ff,
  'm3-sys-light-on-secondary-container': #001e2e,
  'm3-sys-light-tertiary': var(--m3-ref-tertiary-tertiary40),
  'm3-sys-light-on-tertiary': var(--m3-ref-tertiary-tertiary100),
  'm3-sys-light-tertiary-container': var(--m3-ref-tertiary-tertiary90),
  'm3-sys-light-on-tertiary-container': #251a00,
  'm3-sys-light-error': #ba1a1a,
  'm3-sys-light-error-container': #ffdad6,
  'm3-sys-light-on-error': #ffffff,
  'm3-sys-light-on-error-container': #410002,
  'm3-sys-light-background': #fcfcff,
  'm3-sys-light-on-background': #1a1c1e,
  'm3-sys-light-surface': #fcfcff,
  'm3-sys-light-on-surface': #1a1c1e,
  'm3-sys-light-surface-variant': #dee3eb,
  'm3-sys-light-on-surface-variant': #42474e,
  'm3-sys-light-outline': #72777f,
  'm3-sys-light-outline-variant': #cac4d0,
  'm3-sys-light-inverse-on-surface': #f1f0f4,
  'm3-sys-light-inverse-surface': #2f3033,
  'm3-sys-light-surface-tint': #00629d,
  'm3-sys-light-surface-tint-color': #00629d,
  'm3-sys-light-shadow': #000000,
  'm3-sys-light-inverse-primary': #99cbff,
  'm3-sys-dark-primary': #99cbff,
  'm3-sys-dark-on-primary': #003354,
  'm3-sys-dark-primary-container': #004a78,
  'm3-sys-dark-on-primary-container': #cfe5ff,
  'm3-sys-dark-secondary': #87ceff,
  'm3-sys-dark-on-secondary': #00344d,
  'm3-sys-dark-secondary-container': #004c6d,
  'm3-sys-dark-on-secondary-container': #c8e6ff,
  'm3-sys-dark-tertiary': #f7be00,
  'm3-sys-dark-on-tertiary': #3f2e00,
  'm3-sys-dark-tertiary-container': var(--m3-ref-tertiary-tertiary30),
  'm3-sys-dark-on-tertiary-container': var(--m3-ref-tertiary-tertiary90),
  'm3-sys-dark-error': #ffb4ab,
  'm3-sys-dark-error-container': #93000a,
  'm3-sys-dark-on-error': #690005,
  'm3-sys-dark-on-error-container': #ffdad6,
  'm3-sys-dark-background': #1a1c1e,
  'm3-sys-dark-on-background': #e2e2e5,
  'm3-sys-dark-surface': #1a1c1e,
  'm3-sys-dark-on-surface': #e2e2e5,
  'm3-sys-dark-surface-variant': #42474e,
  'm3-sys-dark-on-surface-variant': #c2c7cf,
  'm3-sys-dark-outline': #8c9199,
  'm3-sys-dark-outline-variant': #49454f,
  'm3-sys-dark-inverse-on-surface': #1a1c1e,
  'm3-sys-dark-inverse-surface': #e2e2e5,
  'm3-sys-dark-surface-tint': #99cbff,
  'm3-sys-dark-surface-tint-color': #99cbff,
  'm3-sys-dark-shadow': #000000,
  'm3-sys-dark-inverse-primary': #00629d,
  'm3-ref-primary-primary100': #ffffff,
  'm3-ref-primary-primary99': #fcfcff,
  'm3-ref-primary-primary95': #e8f1ff,
  'm3-ref-primary-primary90': #cfe5ff,
  'm3-ref-primary-primary80': #99cbff,
  'm3-ref-primary-primary70': #67b1f4,
  'm3-ref-primary-primary60': #4996d8,
  'm3-ref-primary-primary50': #277cbc,
  'm3-ref-primary-primary40': #00629d,
  'm3-ref-primary-primary30': #004a78,
  'm3-ref-primary-primary20': #003354,
  'm3-ref-primary-primary10': #001d34,
  'm3-ref-primary-primary0': #000000,
  'm3-ref-primary-primary25': #003e66,
  'm3-ref-primary-primary35': #00568a,
  'm3-ref-primary-primary98': #f7f9ff,
  'm3-ref-secondary-secondary100': #ffffff,
  'm3-ref-secondary-secondary99': #fbfcff,
  'm3-ref-secondary-secondary95': #e5f2ff,
  'm3-ref-secondary-secondary90': #c8e6ff,
  'm3-ref-secondary-secondary80': #87ceff,
  'm3-ref-secondary-secondary70': #4fb4ef,
  'm3-ref-secondary-secondary60': #2a99d3,
  'm3-ref-secondary-secondary50': #007fb3,
  'm3-ref-secondary-secondary40': #00658f,
  'm3-ref-secondary-secondary30': #004c6d,
  'm3-ref-secondary-secondary20': #00344d,
  'm3-ref-secondary-secondary10': #001e2e,
  'm3-ref-secondary-secondary0': #000000,
  'm3-ref-secondary-secondary25': #00405d,
  'm3-ref-secondary-secondary35': #00587e,
  'm3-ref-secondary-secondary98': #f6faff,
  'm3-ref-tertiary-tertiary100': #ffffff,
  'm3-ref-tertiary-tertiary99': #fffbff,
  'm3-ref-tertiary-tertiary95': #ffefd2,
  'm3-ref-tertiary-tertiary90': #ffdf99,
  'm3-ref-tertiary-tertiary80': #f7be00,
  'm3-ref-tertiary-tertiary70': #d6a400,
  'm3-ref-tertiary-tertiary60': #b58a00,
  'm3-ref-tertiary-tertiary50': #967200,
  'm3-ref-tertiary-tertiary40': #775a00,
  'm3-ref-tertiary-tertiary30': #5a4300,
  'm3-ref-tertiary-tertiary20': #3f2e00,
  'm3-ref-tertiary-tertiary10': #251a00,
  'm3-ref-tertiary-tertiary0': #000000,
  'm3-ref-tertiary-tertiary25': #4c3800,
  'm3-ref-tertiary-tertiary35': #684f00,
  'm3-ref-tertiary-tertiary98': #fff8f2,
  'm3-ref-neutral-neutral100': #ffffff,
  'm3-ref-neutral-neutral99': #fcfcff,
  'm3-ref-neutral-neutral95': #f1f0f4,
  'm3-ref-neutral-neutral90': #e2e2e5,
  'm3-ref-neutral-neutral80': #c6c6c9,
  'm3-ref-neutral-neutral70': #aaabae,
  'm3-ref-neutral-neutral60': #909094,
  'm3-ref-neutral-neutral50': #76777a,
  'm3-ref-neutral-neutral40': #5d5e61,
  'm3-ref-neutral-neutral30': #45474a,
  'm3-ref-neutral-neutral20': #2f3033,
  'm3-ref-neutral-neutral10': #1a1c1e,
  'm3-ref-neutral-neutral0': #000000,
  'm3-ref-neutral-neutralNaN': #ffffff,
  'm3-ref-neutral-neutral25': #3a3b3e,
  'm3-ref-neutral-neutral35': #515255,
  'm3-ref-neutral-neutral98': #f9f9fc,
  'm3-ref-neutral-variant-neutral-variant100': #ffffff,
  'm3-ref-neutral-variant-neutral-variant99': #fcfcff,
  'm3-ref-neutral-variant-neutral-variant95': #edf1f9,
  'm3-ref-neutral-variant-neutral-variant90': #dee3eb,
  'm3-ref-neutral-variant-neutral-variant80': #c2c7cf,
  'm3-ref-neutral-variant-neutral-variant70': #a7abb3,
  'm3-ref-neutral-variant-neutral-variant60': #8c9199,
  'm3-ref-neutral-variant-neutral-variant50': #72777f,
  'm3-ref-neutral-variant-neutral-variant40': #5a5f66,
  'm3-ref-neutral-variant-neutral-variant30': #42474e,
  'm3-ref-neutral-variant-neutral-variant20': #2c3137,
  'm3-ref-neutral-variant-neutral-variant10': #171c22,
  'm3-ref-neutral-variant-neutral-variant0': #000000,
  'm3-ref-neutral-variant-neutral-variant25': #373c42,
  'm3-ref-neutral-variant-neutral-variant35': #4e535a,
  'm3-ref-neutral-variant-neutral-variant98': #f7f9ff,
  'm3-ref-error-error100': #ffffff,
  'm3-ref-error-error99': #fffbff,
  'm3-ref-error-error95': #ffedea,
  'm3-ref-error-error90': #ffdad6,
  'm3-ref-error-error80': #ffb4ab,
  'm3-ref-error-error70': #ff897d,
  'm3-ref-error-error60': #ff5449,
  'm3-ref-error-error50': #de3730,
  'm3-ref-error-error40': #ba1a1a,
  'm3-ref-error-error30': #93000a,
  'm3-ref-error-error20': #690005,
  'm3-ref-error-error10': #410002,
  'm3-ref-error-error0': #000000,
  'm3-ref-error-error25': #7e0007,
  'm3-ref-error-error35': #a80710,
  'm3-ref-error-error98': #fff8f7,
  'm3-state-layers-light-surface-tint-opacity-08': #00629d14,
  'm3-state-layers-light-surface-tint-opacity-12': #00629d1f,
  'm3-state-layers-light-surface-tint-opacity-16': #00629d29,
  'm3-state-layers-light-surface-tint-color-opacity-08': #00629d14,
  'm3-state-layers-light-surface-tint-color-opacity-12': #00629d1f,
  'm3-state-layers-light-surface-tint-color-opacity-16': #00629d29,
  'm3-state-layers-light-on-error-container-opacity-08': #41000214,
  'm3-state-layers-light-on-error-container-opacity-12': #4100021f,
  'm3-state-layers-light-on-error-container-opacity-16': #41000229,
  'm3-state-layers-light-on-error-opacity-08': #ffffff14,
  'm3-state-layers-light-on-error-opacity-12': #ffffff1f,
  'm3-state-layers-light-on-error-opacity-16': #ffffff29,
  'm3-state-layers-light-error-container-opacity-08': #ffdad614,
  'm3-state-layers-light-error-container-opacity-12': #ffdad61f,
  'm3-state-layers-light-error-container-opacity-16': #ffdad629,
  'm3-state-layers-light-on-tertiary-container-opacity-08': #251a0014,
  'm3-state-layers-light-on-tertiary-container-opacity-12': #251a001f,
  'm3-state-layers-light-on-tertiary-container-opacity-16': #251a0029,
  'm3-state-layers-light-on-tertiary-opacity-08': #ffffff14,
  'm3-state-layers-light-on-tertiary-opacity-12': #ffffff1f,
  'm3-state-layers-light-on-tertiary-opacity-16': #ffffff29,
  'm3-state-layers-light-tertiary-container-opacity-08': #ffdf9914,
  'm3-state-layers-light-tertiary-container-opacity-12': #ffdf991f,
  'm3-state-layers-light-tertiary-container-opacity-16': #ffdf9929,
  'm3-state-layers-light-tertiary-opacity-08': #775a0014,
  'm3-state-layers-light-tertiary-opacity-12': #775a001f,
  'm3-state-layers-light-tertiary-opacity-16': #775a0029,
  'm3-state-layers-light-shadow-opacity-08': #00000014,
  'm3-state-layers-light-shadow-opacity-12': #0000001f,
  'm3-state-layers-light-shadow-opacity-16': #00000029,
  'm3-state-layers-light-error-opacity-08': #ba1a1a14,
  'm3-state-layers-light-error-opacity-12': #ba1a1a1f,
  'm3-state-layers-light-error-opacity-16': #ba1a1a29,
  'm3-state-layers-light-outline-opacity-08': #72777f14,
  'm3-state-layers-light-outline-opacity-12': #72777f1f,
  'm3-state-layers-light-outline-opacity-16': #72777f29,
  'm3-state-layers-light-on-background-opacity-08': #1a1c1e14,
  'm3-state-layers-light-on-background-opacity-12': #1a1c1e1f,
  'm3-state-layers-light-on-background-opacity-16': #1a1c1e29,
  'm3-state-layers-light-background-opacity-08': #fcfcff14,
  'm3-state-layers-light-background-opacity-12': #fcfcff1f,
  'm3-state-layers-light-background-opacity-16': #fcfcff29,
  'm3-state-layers-light-inverse-on-surface-opacity-08': #f1f0f414,
  'm3-state-layers-light-inverse-on-surface-opacity-12': #f1f0f41f,
  'm3-state-layers-light-inverse-on-surface-opacity-16': #f1f0f429,
  'm3-state-layers-light-inverse-surface-opacity-08': #2f303314,
  'm3-state-layers-light-inverse-surface-opacity-12': #2f30331f,
  'm3-state-layers-light-inverse-surface-opacity-16': #2f303329,
  'm3-state-layers-light-on-surface-variant-opacity-08': #42474e14,
  'm3-state-layers-light-on-surface-variant-opacity-12': #42474e1f,
  'm3-state-layers-light-on-surface-variant-opacity-16': #42474e29,
  'm3-state-layers-light-on-surface-opacity-08': #1a1c1e14,
  'm3-state-layers-light-on-surface-opacity-12': #1a1c1e1f,
  'm3-state-layers-light-on-surface-opacity-16': #1a1c1e29,
  'm3-state-layers-light-surface-variant-opacity-08': #dee3eb14,
  'm3-state-layers-light-surface-variant-opacity-12': #dee3eb1f,
  'm3-state-layers-light-surface-variant-opacity-16': #dee3eb29,
  'm3-state-layers-light-surface-opacity-08': #fcfcff14,
  'm3-state-layers-light-surface-opacity-12': #fcfcff1f,
  'm3-state-layers-light-surface-opacity-16': #fcfcff29,
  'm3-state-layers-light-on-secondary-container-opacity-08': #001e2e14,
  'm3-state-layers-light-on-secondary-container-opacity-12': #001e2e1f,
  'm3-state-layers-light-on-secondary-container-opacity-16': #001e2e29,
  'm3-state-layers-light-on-secondary-opacity-08': #ffffff14,
  'm3-state-layers-light-on-secondary-opacity-12': #ffffff1f,
  'm3-state-layers-light-on-secondary-opacity-16': #ffffff29,
  'm3-state-layers-light-secondary-container-opacity-08': #c8e6ff14,
  'm3-state-layers-light-secondary-container-opacity-12': #c8e6ff1f,
  'm3-state-layers-light-secondary-container-opacity-16': #c8e6ff29,
  'm3-state-layers-light-secondary-opacity-08': #00658f14,
  'm3-state-layers-light-secondary-opacity-12': #00658f1f,
  'm3-state-layers-light-secondary-opacity-16': #00658f29,
  'm3-state-layers-light-inverse-primary-opacity-08': #99cbff14,
  'm3-state-layers-light-inverse-primary-opacity-12': #99cbff1f,
  'm3-state-layers-light-inverse-primary-opacity-16': #99cbff29,
  'm3-state-layers-light-on-primary-container-opacity-08': #001d3414,
  'm3-state-layers-light-on-primary-container-opacity-12': #001d341f,
  'm3-state-layers-light-on-primary-container-opacity-16': #001d3429,
  'm3-state-layers-light-on-primary-opacity-08': #ffffff14,
  'm3-state-layers-light-on-primary-opacity-12': #ffffff1f,
  'm3-state-layers-light-on-primary-opacity-16': #ffffff29,
  'm3-state-layers-light-primary-container-opacity-08': #cfe5ff14,
  'm3-state-layers-light-primary-container-opacity-12': #cfe5ff1f,
  'm3-state-layers-light-primary-container-opacity-16': #cfe5ff29,
  'm3-state-layers-light-primary-opacity-08': #00629d14,
  'm3-state-layers-light-primary-opacity-12': #00629d1f,
  'm3-state-layers-light-primary-opacity-16': #00629d29,
  'm3-state-layers-dark-surface-tint-opacity-08': #99cbff14,
  'm3-state-layers-dark-surface-tint-opacity-12': #99cbff1f,
  'm3-state-layers-dark-surface-tint-opacity-16': #99cbff29,
  'm3-state-layers-dark-surface-tint-color-opacity-08': #99cbff14,
  'm3-state-layers-dark-surface-tint-color-opacity-12': #99cbff1f,
  'm3-state-layers-dark-surface-tint-color-opacity-16': #99cbff29,
  'm3-state-layers-dark-on-error-container-opacity-08': #ffdad614,
  'm3-state-layers-dark-on-error-container-opacity-12': #ffdad61f,
  'm3-state-layers-dark-on-error-container-opacity-16': #ffdad629,
  'm3-state-layers-dark-on-error-opacity-08': #69000514,
  'm3-state-layers-dark-on-error-opacity-12': #6900051f,
  'm3-state-layers-dark-on-error-opacity-16': #69000529,
  'm3-state-layers-dark-error-container-opacity-08': #93000a14,
  'm3-state-layers-dark-error-container-opacity-12': #93000a1f,
  'm3-state-layers-dark-error-container-opacity-16': #93000a29,
  'm3-state-layers-dark-on-tertiary-container-opacity-08': #ffdf9914,
  'm3-state-layers-dark-on-tertiary-container-opacity-12': #ffdf991f,
  'm3-state-layers-dark-on-tertiary-container-opacity-16': #ffdf9929,
  'm3-state-layers-dark-on-tertiary-opacity-08': #3f2e0014,
  'm3-state-layers-dark-on-tertiary-opacity-12': #3f2e001f,
  'm3-state-layers-dark-on-tertiary-opacity-16': #3f2e0029,
  'm3-state-layers-dark-tertiary-container-opacity-08': #5a430014,
  'm3-state-layers-dark-tertiary-container-opacity-12': #5a43001f,
  'm3-state-layers-dark-tertiary-container-opacity-16': #5a430029,
  'm3-state-layers-dark-tertiary-opacity-08': #f7be0014,
  'm3-state-layers-dark-tertiary-opacity-12': #f7be001f,
  'm3-state-layers-dark-tertiary-opacity-16': #f7be0029,
  'm3-state-layers-dark-shadow-opacity-08': #00000014,
  'm3-state-layers-dark-shadow-opacity-12': #0000001f,
  'm3-state-layers-dark-shadow-opacity-16': #00000029,
  'm3-state-layers-dark-error-opacity-08': #ffb4ab14,
  'm3-state-layers-dark-error-opacity-12': #ffb4ab1f,
  'm3-state-layers-dark-error-opacity-16': #ffb4ab29,
  'm3-state-layers-dark-outline-opacity-08': #8c919914,
  'm3-state-layers-dark-outline-opacity-12': #8c91991f,
  'm3-state-layers-dark-outline-opacity-16': #8c919929,
  'm3-state-layers-dark-on-background-opacity-08': #e2e2e514,
  'm3-state-layers-dark-on-background-opacity-12': #e2e2e51f,
  'm3-state-layers-dark-on-background-opacity-16': #e2e2e529,
  'm3-state-layers-dark-background-opacity-08': #1a1c1e14,
  'm3-state-layers-dark-background-opacity-12': #1a1c1e1f,
  'm3-state-layers-dark-background-opacity-16': #1a1c1e29,
  'm3-state-layers-dark-inverse-on-surface-opacity-08': #1a1c1e14,
  'm3-state-layers-dark-inverse-on-surface-opacity-12': #1a1c1e1f,
  'm3-state-layers-dark-inverse-on-surface-opacity-16': #1a1c1e29,
  'm3-state-layers-dark-inverse-surface-opacity-08': #e2e2e514,
  'm3-state-layers-dark-inverse-surface-opacity-12': #e2e2e51f,
  'm3-state-layers-dark-inverse-surface-opacity-16': #e2e2e529,
  'm3-state-layers-dark-on-surface-variant-opacity-08': #c2c7cf14,
  'm3-state-layers-dark-on-surface-variant-opacity-12': #c2c7cf1f,
  'm3-state-layers-dark-on-surface-variant-opacity-16': #c2c7cf29,
  'm3-state-layers-dark-on-surface-opacity-08': #e2e2e514,
  'm3-state-layers-dark-on-surface-opacity-12': #e2e2e51f,
  'm3-state-layers-dark-on-surface-opacity-16': #e2e2e529,
  'm3-state-layers-dark-surface-variant-opacity-08': #42474e14,
  'm3-state-layers-dark-surface-variant-opacity-12': #42474e1f,
  'm3-state-layers-dark-surface-variant-opacity-16': #42474e29,
  'm3-state-layers-dark-surface-opacity-08': #1a1c1e14,
  'm3-state-layers-dark-surface-opacity-12': #1a1c1e1f,
  'm3-state-layers-dark-surface-opacity-16': #1a1c1e29,
  'm3-state-layers-dark-on-secondary-container-opacity-08': #c8e6ff14,
  'm3-state-layers-dark-on-secondary-container-opacity-12': #c8e6ff1f,
  'm3-state-layers-dark-on-secondary-container-opacity-16': #c8e6ff29,
  'm3-state-layers-dark-on-secondary-opacity-08': #00344d14,
  'm3-state-layers-dark-on-secondary-opacity-12': #00344d1f,
  'm3-state-layers-dark-on-secondary-opacity-16': #00344d29,
  'm3-state-layers-dark-secondary-container-opacity-08': #004c6d14,
  'm3-state-layers-dark-secondary-container-opacity-12': #004c6d1f,
  'm3-state-layers-dark-secondary-container-opacity-16': #004c6d29,
  'm3-state-layers-dark-secondary-opacity-08': #87ceff14,
  'm3-state-layers-dark-secondary-opacity-12': #87ceff1f,
  'm3-state-layers-dark-secondary-opacity-16': #87ceff29,
  'm3-state-layers-dark-inverse-primary-opacity-08': #00629d14,
  'm3-state-layers-dark-inverse-primary-opacity-12': #00629d1f,
  'm3-state-layers-dark-inverse-primary-opacity-16': #00629d29,
  'm3-state-layers-dark-on-primary-container-opacity-08': #cfe5ff14,
  'm3-state-layers-dark-on-primary-container-opacity-12': #cfe5ff1f,
  'm3-state-layers-dark-on-primary-container-opacity-16': #cfe5ff29,
  'm3-state-layers-dark-on-primary-opacity-08': #00335414,
  'm3-state-layers-dark-on-primary-opacity-12': #0033541f,
  'm3-state-layers-dark-on-primary-opacity-16': #00335429,
  'm3-state-layers-dark-primary-container-opacity-08': #004a7814,
  'm3-state-layers-dark-primary-container-opacity-12': #004a781f,
  'm3-state-layers-dark-primary-container-opacity-16': #004a7829,
  'm3-state-layers-dark-primary-opacity-08': #99cbff14,
  'm3-state-layers-dark-primary-opacity-12': #99cbff1f,
  'm3-state-layers-dark-primary-opacity-16': #99cbff29,
  'm3-sys-light-background-full-opacity': #e8f0f7,
  'm3-sys-dark-background-full-opacity': #242a30
);

@include colors-variation($colors);


$surfaces: (
  '0': 0,
  '1': 0.05,
  '2': 0.08,
  '3': 0.11,
  '4': 0.12,
  '5': 0.14
);

:root {

  @each $item,
  $val in $surfaces {
    $surfaceColor: map.get($colors, "m3-sys-light-primary");
    $rgbaSruface: rgba($surfaceColor, $val);
    --color-surface-#{$item}: #{$rgbaSruface};
  }
}

#{$dark-theme} {

  @each $item,
  $val in $surfaces {
    $surfaceColor: map.get($colors, "m3-sys-dark-primary");
    $rgbaSruface: rgba($surfaceColor, $val);
    --color-surface-#{$item}: #{$rgbaSruface};
  }
}

%sys-bg {
  --bg: var(--m3-sys-background);
  --fg: var(--color-surface-1);
  --color: var(--m3-sys-on-surface);
  background-color: var(--bg);
  color: var(--color);
  position: relative;
  background: linear-gradient(0deg, var(--fg), var(--fg)), var(--bg);
}

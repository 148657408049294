body {
  .mdc-card {
    @extend %sys-bg;
    position: relative;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: none !important;

    &:not([class*=mat-elevation-z]) {
      box-shadow: var(--m3-sys-elevation-2);
    }

    .mdc-card__actions:last-child {
      margin: var(--space-l) var(--space-none) var(--space-none) var(--space-none);
      padding: 0;
    }
  }

  .mat-mdc-card-image {
    width: calc(100% + 2rem);
    margin: 0 -1rem 1rem -1rem;
    height: 188px;
    object-fit: cover;
  }

  .mat-mdc-card-header {
    align-items: center;
    padding: 0;

    .mat-mdc-card-header-text {
      flex: 1;
      margin-inline-start: var(--space-base);
      margin-inline-end: 0;
    }
    &.mat-card-header--no-margin{
      .mat-mdc-card-header-text{
      margin: 0;
      }
    }
    .mat-mdc-card-title {
      color: var(--m3-sys-on-surface);
      @extend .typo-title-medium;
      font-size: var(--font-size-medium);
      line-height: 20px;
    }

    .mat-mdc-card-subtitle {
      color: var(--m3-sys-on-surface);
      @extend .typo-body-medium;

      &:not(:first-child) {
        margin-top: 0;
      }
    }

    +* {
      margin-top: var(--space-base);
    }
  }

  .mat-mdc-card-content {
    @extend .typo-body-medium;
    color: var(--m3-sys-on-surface-variant);
    padding: 0;
  }


  .card-outlined {
    background: var(--m3-sys-surface);
    border: 1px solid var(--m3-sys-outline-variant);

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }
  }

  .card-filled {
    --bg: var(--m3-sys-surface-variant);

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }
  }

}

.box-shadow-none {
  box-shadow: none !important;
}

.background-inherit {
  background: inherit !important;
}

.bg-light {
  background-color: var(--m3-sys-surface-variant) !important;
  color: var(--m3-sys-on-surface-variant) !important;
}

#{$dark-theme} {
  .card-back-drop {
    background-color: transparent !important;
    border: 2px solid var(--m3-sys-surface-variant);
  }
}

body {

  .mat-mdc-card-avatar~.mat-mdc-card-header-text .mat-mdc-card-title,
  .mat-mdc-card-avatar~.mat-mdc-card-header-text .mat-mdc-card-subtitle {
    padding: 0;
  }

  .mat-mdc-card-avatar {
    margin-bottom: 0;
  }

  .mat-mdc-card-title {
    color: var(--m3-sys-on-surface);
    line-height: 20px;
  }

  .mat-mdc-card-subtitle {
    color: var(--m3-sys-on-surface);
  }

  .mat-mdc-card-subtitle~.mat-mdc-card-title,
  .mat-mdc-card-title~.mat-mdc-card-subtitle,
  .mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title,
  .mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-subtitle,
  .mat-mdc-card-title-group .mat-mdc-card-title,
  .mat-mdc-card-title-group .mat-mdc-card-subtitle {
    font-weight: var(--font-size-regular);
  }

  .mat-mdc-card-content:last-child {
    padding: 0;
  }
  .mat-mdc-card-footer{
    display: block;
  }
}

app-root {
  flex: 1;
  display: flex;
  height: 100%;
}

body {
  @extend %sys-bg;
  padding-top: var(--header-height);
  padding-inline-start: var(--content-start-padding);
  padding-inline-end: var(--content-end-padding);
  padding-bottom: calc(var(--bottom-bar-height) + var(--space-m));
  opacity: 0;
  transition-property: padding, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);

  [dir="rtl"] & {
    text-align: right;
  }

  &::before {
    position: fixed;
  }

  @include media-breakpoint-up(sm) {
    padding-inline-start: var(--nav-width);
    padding-bottom: var(--space-m);
  }
}

body.body--login {
  padding: var(--space-none);
  padding-inline-start: var(--space-none);

  @include media-breakpoint-up(sm) {
    padding-bottom: var(--space-none);
  }

  .layout__body {
    height: auto;
    width: 100%;
    padding: var(--space-xl);
    padding-bottom: var(--space-none);
    @extend %sys-bg;
  }
}

.body--show {
  opacity: 1;
}

.layout {
  color: var(--m3-sys-on-surface);
  width: 100%;
}

.layout--expanded {
  --nav-width: var(--expanded-nav-width);
}

.layout__actions {
  width: var(--actions-width);
  margin: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  height: 100%;

  [dir=rtl] & {
    left: 0;
    right: auto;
  }
}

.layout--collapsed {
  --nav-width: var(--collapsed-nav-width);
}

.layout--closed {
  padding-inline-start: var(--space-m);

  .toggle-body {
    [dir=rtl] & {
      transform: translate(-15%, 0);
    }
  }
}

.layout__header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  @extend %sys-bg;
}

.layout__nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--color-surface-1);

  @include media-breakpoint-up(sm) {
    background-color: transparent;
    // overflow: auto;
    width: var(--nav-width);
    top: var(--header-height);
    left: 0;

    [dir=rtl] & {
      right: 0;
      left: auto;
    }
  }
}

.layout__content {
  display: flex;
  // min-height: calc(100vh - var(--header-height) - var(--space-m));
  min-height: 100%;
}

.toggle-body {
  position: fixed;
  left: var(--nav-width);
  transform: translate(-50%, 0);
  top: 145px;
  background-color: var(--m3-sys-surface) !important;
  color: var(--m3-sys-primary);
  transition-property: left;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  left: var(--nav-width);
  transform: translate(-50%, 0);

  &.mat-mdc-icon-button {
    position: fixed;
  }

  .layout--closed & {
    transform: translate(0, 0);
  }

  &:hover {
    background: var(--m3-sys-surface);
    color: var(--m3-sys-primary);
  }

  [dir="rtl"] & {
    right: var(--nav-width);
    left: auto;
    transform: translate(50%, 0);
  }
}

.layout__body {
  flex: 1;
  position: relative;
  background: var(--m3-sys-surface);
  border-radius: var(--border-radius-m);
  padding: var(--space-m);

  .toggle-body--end {
    right: var(--actions-width);
    bottom: 80px;
    top: auto;
    left: auto;
    z-index: 20;
    transition-property: right;
    transform: translate(-15%, 0);

    [dir="rtl"] & {
      transform: translate(0, 0);
      left: var(--actions-width);
      right: auto;
      transition-property: left;
    }
  }

}

.layout--expanded-end {
  --actions-width: var(--expanded-actions-width);

  @include media-breakpoint-up(sm) {
    padding-inline-end: var(--actions-width);
  }

  .toggle-body--end {
    transform: translate(50%, 0);

    [dir="rtl"] & {
      transform: translate(-50%, 0);
    }
  }
}

.transition-none {
  transition: none;
}

@media print {

  .top-bar,
  .side-nav,
  .chat-box,
  .layout__header,
  .layout__nav {
    display: none !important;
  }

  html,
  body {
    background: none;
  }
}

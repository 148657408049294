.date-picker {
  .mat-calendar {
    padding: 0 !important;
    top: 0 !important;
    background: var(--color-surface-5) !important;
    margin: 0 !important;
    max-width: unset !important;
    border-radius: 28px;

    table {

      th,
      td,
      .mat-calendar-body-cell-content,
      .mat-date-range-input-separator {
        color: var(--m3-sys-on-surface);
        font-size: var(--font-size-xs) !important;
        font-weight: var(--font-regular) !important;
        width: 3em !important;
        height: 3em !important;
      }
    }

    .mat-calendar-arrow {
      fill: var(--m3-sys-on-surface) !important;
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: var(--m3-sys-primary);
      color: var(--m3-sys-on-primary) !important;
    }

    .mat-calendar-body-active .mat-calendar-body-today {
      color: var(--m3-sys-primary) !important;
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      border-color: var(--m3-sys-primary) !important;
    }

    .mat-calendar-body-selected {
      color: var(--m3-sys-on-primary) !important;
    }
  }
}

.received-date {
  .md-drppicker {
    top: 50% !important;
    transform: translateY(-50%) !important;

    &.hidden .buttons {
      display: none;
    }
  }
}

body {

  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .mat-calendar-previous-button::after,
  .mat-calendar-next-button::after {
    width: unset;
    height: unset;
  }

  .date-picker {
    .mat-mdc-button:not(:disabled) {
      color: var(--m3-sys-on-surface);
    }

    .mat-mdc-button-base,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-raised-button {
      min-height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1.5rem;
    }
  }

  .mat-calendar-arrow {
    fill: var(--m3-sys-on-surface);
  }

  .mat-calendar-table-header th {
    color: var(--m3-sys-on-surface);
  }

  .timepicker__main-content {

    .mat-mdc-button-base,
    body .mat-stroked-button,
    body .mat-flat-button,
    body .mat-raised-button {
      padding: 0;
    }
  }
}
.slide-toggle--soft {
  --bg-color: var(--m3-sys-on-surface);
  --color: var(--m3-sys-on-surface);
  --border-radius: 5px;
  --width: 3.25rem;
  --height: 2rem;
  --thumb-margin: 3px;

  &.mat-mdc-slide-toggle {
    border-radius: var(--border-radius-rounded);
    position: relative;

    .mat-slide-toggle-bar {
      background-color: var(--m3-sys-light-surface-variant);
      width: var(--width);
      height: var(--height);
      border-radius: var(--border-radius-rounded);
    }

    &:after {
      display: flex;
      padding: 0 0.625rem;
      font-size: 0.8rem;
      font-weight: bold;
      align-items: center;
      color: var(--color);
      display: flex;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &.mat-mdc-slide-toggle-checked {
      &:after {
        content: attr(data-on);
      }

      .mat-slide-toggle-thumb-container {
        transform: translate(0, 0);
        left: auto;
        right: 6px;
      }
    }

    &:not(.mat-mdc-slide-toggle-checked) {
      &:after {
        content: attr(data-off);
        justify-content: flex-end;
      }

      .mat-slide-toggle-thumb-container {
        transform: translate(0, 0);
        left: 6px;
        right: auto;

        [dir=rtl] & {
          right: 6px;
          left: auto;
        }
      }
    }
  }
}

.input-group__toggle {
  position: relative;
  display: flex;
  align-items: center;

  .input-group__toggle--btn {
    position: absolute;
    height: 100px;
    z-index: 99;
    right: 8px;
    top: calc(100% - 82px);
  }
}

.input-group__toggle--btn-rtl {
  right: unset !important;
  left: 8px;
}

body .mat-slide-toggle.mat-checked {
  .mat-slide-toggle-bar {
    background-color: var(--m3-sys-secondary-container);
  }
}

.mat-slide-toggle.mat-checked.mat-disabled {
  .mat-slide-toggle-bar {
    background-color: var(--m3-state-layers-on-surface-opacity-12);
    border-color: transparent;
  }
}

.mat-slide-toggle.mat-checkedmat-disabled .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  background-color: var(--m3-sys-surface);
}

.slide-toggle--soft.mat-slide-toggle:after {
  color: var(--m3-sys-on-secondary-container);
}

.slide-toggle--soft.mat-mdc-slide-toggle .mat-slide-toggle-bar {
  background-color: var(--m3-sys-secondary-container);
  border: 1px solid var(--m3-sys-primary);
}

.custom-toggle {

  mat-slide-toggle.mat-mdc-slide-toggle-checked {
    [dir=rtl] & {
      .mdc-switch__handle {
        transform: translate(0px, -10px) scale(1) !important;
      }
    }
  }

  .mdc-switch__track {
    border-color: var(--m3-sys-primary) !important;
  }

  .mdc-switch:enabled .mdc-switch__track::after {
    background-color: var(--m3-sys-secondary-container);
  }

  .mdc-switch:enabled .mdc-switch__track::before {
    background-color: var(--m3-sys-secondary-container);
  }

  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
    background-color: var(--m3-sys-secondary-container) !important;
  }

  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    background-color: var(--m3-sys-secondary-container) !important;
  }

  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
    background-color: var(--m3-sys-secondary-container) !important;
  }
}

body  .custom-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background-color: var(--m3-sys-secondary-container) !important;
}

.slide-toggle--soft.mat-mdc-slide-toggle:after {
  font-size: .8em;
  font-weight: 700;
}

.slide-toggle--soft.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:after {
  content: attr(data-on);
  width: fit-content;
}

.custom-toggle .slide-toggle--soft.mat-mdc-slide-toggle:after {
  color: var(--m3-sys-on-secondary-container);
  padding: 0 10px;
  font-size: .8em;
  font-weight: 700;
  color: var(--color);
  position: absolute;
  right: 4px;
  top: 0;
  width: fit-content;
}

.custom-toggle .mat-mdc-slide-toggle-checked.mat-mdc-slide-toggle:after {
  left: 0;
}


[dir=rtl] .custom-toggle .slide-toggle--soft.mat-mdc-slide-toggle:after {
  left: 2px;
  right: auto;
}

[dir=rtl] .custom-toggle .mat-mdc-slide-toggle-checked.mat-mdc-slide-toggle:after {
  right: 2px;
  left:auto;
}

@mixin ltr($prop, $rtl-value, $ltr-value) {
  #{$prop}: $rtl-value;
  [dir="ltr"] & {
    #{$prop}: $ltr-value;
  }
}
@mixin ltr-prop($rtl-prop, $ltr-prop, $value, $reset-value) {
  [dir="rtl"] & {
    #{$rtl-prop}: $value;
    #{$ltr-prop}: $reset-value;
  }
  [dir="ltr"] & {
    #{$rtl-prop}: $reset-value;
    #{$ltr-prop}: $value;
  }
}

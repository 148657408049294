.landing__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing__switch-list {
  color: var(--m3-sys-on-surface);

  .button__icon {
    margin-inline-end: var(--space-s);
  }
}

.landing-list__label {
  color: var(--m3-sys-on-surface);
  font-size: var(--typo-title-small);
  font-weight: var(--font-regular);
  line-height: 20px;
  letter-spacing: 0.25px;
  margin: 0 0 var(--space-base) 0;
}

.landing-list+.landing-list {
  margin-top: var(--space-m);
}

.landing__content {
  margin-top: var(--space-l);
}

.card--landing {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-surface-1);
  padding: var(--space-base);
  border-radius: 20px;
  transition: background-color .3s;
  position: relative;

  &--content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .card__title {
      font-size: var(--font-size-s);
      font-weight: 500;
    }

    &-container {
      width: calc(100% - 40px)
    }

    &--text {
      color: var(--m3-sys-light-on-surface-variant);
      width: 100%;
      margin: .5rem;
      margin-top: 0;
      font-size: var(--font-size-xs);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      z-index: 2;
      text-decoration: none;
      line-height: initial;
    }
  }

  &--link {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 20px;
  }

  &--btn {
    position: relative;
    z-index: 2 !important;

    &.button-icon {
      .card__icon--active {
        color: var(--m3-sys-secondary);
      }
    }
  }

  .card__icon--show {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
  }

  &:hover {
    background-color: var(--m3-state-layers-primary-opacity-12);

    .card__icon--show {
      opacity: 1;
      visibility: visible;
    }
  }


}

.card__title {
  margin: 0;
  margin-bottom: 0;
  color: var(--m3-sys-on-surface);
  font-size: var(--font-size-base);
  font-weight: var(--font-medium);
}
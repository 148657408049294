:root {
  // forms
  --form-label-weight: var(--font-regular);
  --form-label-size: var(--typo-body-large);
  --form-label-color: var(--m3-sys-on-surface);
  --form-label-error: var(--m3-sys-error);

  --input-border-color: var(--m3-sys-outline);
  --input-border-width: 1px;
  --input-border-radius: 4px;
  --input-background: transparent;
  --input-font-size: var(--typo-body-large);
  --input-font-weight: var(--font-regular);
  --input-height: 56px;
}

body {
  .mat-form-field {
    width: 100%;
    /* display: block; */
    margin-bottom: var(--space-base);
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  .mat-form-field-appearance-outline {
    --color: var(--m3-sys-on-background);

    &.mat-focused {
      --input-border-width: 2px;
      //--color: var(--m3-sys-primary);
    }

    &.mat-form-field-invalid {
      --input-border-width: 2px;
      --color: var(--m3-sys-error) !important;
    }

    &.mat-mdc-form-field-error {
      --input-border-width: 2px;
      --color: var(--m3-sys-error) !important;
    }

    &.mat-form-field-disabled {
      --color: var(--m3-sys-on-surface);
      opacity: 0.38;
    }

    mat-label:not(.form-placeholder),
    .mat-placeholder-required {
      font-size: var(--form-label-size);
      font-weight: var(--form-label-weight);
      color: var(--color);
      text-transform: capitalize;
    }

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      background: var(--input-background);
      color: var(--color);
    }

    .mat-form-field-outline-thick {

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        transition: border .35s ease-in-out;
      }
    }

    .mat-mdc-form-field-input-control {
      font-size: var(--input-font-size);
      font-weight: var(--input-font-weight);

      &::placeholder {
        color: var(--m3-sys-outline);
      }
    }

    textarea {
      /* min-height: 120px; */
    }

    .mat-form-field-outline {
      top: 0;
    }

    .mat-form-field-flex {
      min-height: var(--input-height);
      align-items: center;
    }

    .mat-mdc-form-field-infix {
      border-top: 0 solid transparent;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .mat-form-field-label-wrapper {
      [dir="ltr"] & {
        top: -0.5em;
        padding-top: 0.5em;
      }
    }

    .mat-select-value-text,
    mat-label,
    .mat-input-element {
      [dir="rtl"] & {
        line-height: 1.7;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0;
  }

  .mat-hint {
    color: var(--m3-sys-on-surface);
  }

  .m3-sys-error {
    .mat-form-field-appearance-outline.mat-form-field-invalid {
      color: var(--m3-sys-error);
    }
  }

  .mat-select-arrow {
    color: var(--m3-sys-outline);
  }

  .mat-select-value {
    color: var(--color);
  }

  .mat-mdc-select-placeholder {
    color: var(--m3-sys-outline);
  }
}

.sales-order__discount-panel--input .mat-mdc-text-field-wrapper {
  padding-bottom: .25em;
}

.search-control {
  .mat-mdc-input-element {
    margin-top: 0 !important;
  }

  .mat-form-field-outline {
    position: relative;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    margin-bottom: .85em !important;
  }

  .mat-mdc-form-field-infix {
    .dropdown-menu {
      transform: translate3d(0px, 53px, 0px) !important;
      padding: 0;
      border: 0;
      border-radius: 0;
      min-height: auto;
      height: auto;
      max-height: 400px;
    }
  }

  .mat-mdc-form-field-flex {

    background-color: var(--color-surface-2);
    color: var(--m3-sys-on-background);
    border-radius: var(--border-radius-rounded);
    padding: 0 0.75em 0 0.75em !important;

    icon {
      padding: 0 8px;
      color: var(--m3-sys-on-background);
    }

    .mdc-notched-outline div {
      border-color: transparent !important;
    }

    .mat-input-element {
      color: var(--m3-sys-on-background);
      padding-inline-start: 8px;
    }
  }

  &--md {
    .mat-mdc-form-field-flex {
      --input-height: 44px;
      min-height: var(--input-height) !important;
      height: 44px;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix,
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      --mat-form-padding: 10px;
      padding-top: var(--mat-form-padding);
      padding-bottom: var(--mat-form-padding);
    }
  }

  &--border-radius-m {
    .mat-mdc-form-field-flex {
      border-radius: var(--border-radius-m);
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--color);
  }
}

.mat-custom {
  .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0.6em 0 0.6em 0;

  }


  .mat-mdc-input-element {
    margin-top: 0.7em;
  }


  .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    min-height: unset;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }



  .mat-mdc-input-element {
    color: var(--color) !important;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }
}


.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--m3-sys-on-background);
}


.mat-icon-button {
  color: var(--m3-sys-on-background);

  svg {
    fill: var(--m3-sys-on-surface);
  }

  &--primary {
    svg {
      fill: var(--m3-sys-primary);
    }
  }

  &--warn {
    svg {
      fill: var(--m3-sys-error);
    }
  }
}

.form-group--select {
  &.mat-custom {
    .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding: 1em;

    }

    .mat-select-arrow {
      margin-top: 12px;
    }
  }
}

.field-space-none {
  margin-bottom: 0 !important;

  &.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    margin-bottom: .25em !important;
  }
}

.field-bg-white {
  .ng-star-inserted.mat-form-field-outline {
    background-color: var(--m3-sys-surface);
    border-radius: 5px;
  }

  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-flex,
  .mat-mdc-text-field-wrapper {
    background-color: var(--m3-sys-surface);
  }
}

.field-bg-surface-2 {

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    background-color: var(--color-surface-2);
    border: none !important;
  }
}

.field-border-none {

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border: none !important;
  }

  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-flex,
  .mat-mdc-text-field-wrapper {
    border-radius: var(--border-radius-m);
  }
}

.custom-search-field {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: .25em;
  }
}

.mat-custom {
  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    box-shadow: 0px 2px 1px -1px rgb(0 145 234 / 20%), 0px 1px 1px 0px rgb(0 145 234 / 14%),
      0px 1px 3px 0px rgb(0 145 234 / 12%) !important;
  }
}

body .field-error {

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    color: var(--m3-sys-error) !important;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    box-shadow: none !important;
  }

  &.mat-form-field-appearance-outline {
    color: var(--m3-sys-error) !important;

    &.mat-focused {
      color: var(--m3-sys-error) !important;
    }
  }
}

// datepicker
.md-drppicker {
  padding: 0 !important;
  background: none !important;
  top: 0 !important;

  table {
    border-spacing: 0px 6px;
    border-collapse: unset;
  }

  .calendar {
    background: var(--m3-sys-surface) !important;
    margin: 0 !important;
    max-width: unset !important;

    td,
    th {
      min-width: 3em !important;
    }

    th {
      height: 2em !important;
      vertical-align: baseline;
    }

    th.month {
      font-size: var(--typo-headline-large) !important;
    }
  }

  .calendar-table {
    background: var(--color-surface-3) !important;
    border: none !important;
    padding: var(--space-m) var(--space-m) 0 var(--space-m) !important;
  }

  &.double {
    .calendar-table {
      padding: var(--space-m) !important;
    }
  }

  .calendar-time {
    margin: 0 !important;
    background: var(--color-surface-3) !important;

    .select .select-item {
      color: var(--color-on-surface-3) !important;
      border-bottom: 1px solid var(--m3-sys-outline-variant) !important;

      option {
        background: var(--m3-sys-surface) !important;
        color: var(--m3-sys-on-surface) !important;
      }
    }
  }

  .buttons {
    margin: 0 !important;
    background: var(--m3-sys-surface) !important;
  }

  .buttons_input {
    background: var(--color-surface-3) !important;
  }

  .btn {
    margin: var(--space-s);
    background-color: transparent !important;
    color: var(--m3-sys-primary) !important;
    box-shadow: none !important;
  }

  th,
  td {
    color: var(--color-on-surface-3) !important;
    font-size: var(--font-size-xs) !important;
    font-weight: var(--font-regular) !important;
    width: 3em !important;
    height: 3em !important;
  }

  td {
    transition: none;

    &.active,
    .active:hover {
      background-color: var(--m3-sys-primary) !important;
      color: var(--m3-sys-on-primary) !important;
    }

    &.available:hover {
      background-color: var(--m3-sys-primary) !important;
      color: var(--m3-sys-on-primary) !important;
    }

    &.off:not(.disabled) {
      // background-color: unset !important;
      // color: var(--m3-sys-on-surface-variant) !important;
      visibility: hidden;
      transition: none;
      color: transparent;
      background-color: transparent;
    }

    &.disabled {
      text-decoration: none !important;
      background-color: transparent !important;
      color: var(--m3-sys-outline) !important;
      opacity: .5;
    }

    &.in-range {
      background-color: var(--m3-sys-secondary-container) !important;
    }
  }

  td.start-date.end-date {
    border-radius: 50% !important;
    border: 0 !important;
  }

  th.available.prev {
    background: none !important;

    &::before {
      font-family: "Material Icons";
      content: url('../../assets/images/chevron-left.svg');
      position: absolute;
      width: 24px;
      height: 24px;
    }
  }

  th.available.next {
    transform: unset !important;
    background: none !important;

    &::after {
      font-family: "Material Icons";
      content: url('../../assets/images/chevron-right.svg');
      position: absolute;
      width: 24px;
      height: 24px;
    }
  }

}

.mat-datepicker-content {
  background: var(--m3-sys-surface) !important;

  .mat-datepicker-content-container {
    background: var(--color-surface-3) !important;
  }

  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator,
  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button,
  .mat-calendar-table-header,
  .mat-calendar-body-label {
    color: var(--m3-sys-on-surface) !important;
  }

  .mat-button {
    background: transparent;
    color: var(--m3-sys-on-background);

    .mat-calendar-arrow {
      fill: var(--m3-sys-on-background) !important;
    }
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--m3-sys-primary) !important;
  }

  .mat-datepicker-toggle,
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button {
    color: var(--m3-sys-on-surface) !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: var(--m3-sys-on-primary) !important;
    background-color: var(--m3-sys-primary) !important;
  }

  .mat-calendar-body-active {
    color: var(--m3-sys-on-primary) !important;
  }

  .mat-calendar-body-selected {
    background-color: var(--m3-sys-primary) !important;
    color: var(--m3-sys-on-primary) !important;
  }
}

.mat-calendar-body-active {

  .mat-calendar-body-selected {
    background-color: var(--m3-sys-primary) !important;
    color: var(--m3-sys-on-primary) !important;
  }
}

.mat-calendar-body-active {
  .mat-calendar-body-today {
    background: transparent !important;
    color: var(--m3-sys-primary) !important;
  }
}

.mat-calendar-body-active .mat-calendar-body-today:is(.mat-calendar-body-selected) {
  background-color: var(--m3-sys-primary) !important;
  color: var(--m3-sys-on-primary) !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--m3-sys-on-surface) !important;
}

.chip-list-field {
  // &.mat-form-field {
  //   border-radius: 5px;
  //   padding: 0 10px;


  // }

  // .mat-form-field-outline-start,
  // .mat-form-field-outline-end,
  // .mat-form-field-outline-gap {
  //   border: 0 !important;
  // }

  .mat-form-field-label {
    display: inline-block !important;
    width: auto !important;
    background-color: var(--m3-sys-surface);
    color: var(--m3-sys-on-surface);
  }

  // .mat-form-field-appearance-fill .mat-form-field-flex {
  //   border-radius: 0;
  //   padding: 0;
  //   background-color: unset;
  // }

  // .mat-mdc-text-field-wrapper {
  //   margin: 0;
  //   padding-bottom: 0;
  // }

  // .mat-standard-chip.mat-chip-with-trailing-icon,
  // [dir="rtl"] .mat-standard-chip.mat-chip-with-trailing-icon {
  //   height: 0;
  // }

  // .mat-chip-list-wrapper input.mat-input-element,
  // .mat-chip-list-wrapper .mat-standard-chip {
  //   margin: 4px;
  // }

  // .mat-form-field-appearance-fill .mat-mdc-form-field-infix {
  //   border: 0;
  //   padding: 0.09em 0 0.75em 0;
  // }

  // .mat-form-field-underline {
  //   display: none !important;

  //   &::before {
  //     display: none !important;
  //   }
  // }

  // .mat-mdc-form-field-infix {
  //   border-top: 0 !important;
  // }

  // &.form-label--error {
  //   mat-label {
  //     color: var(--m3-sys-error);
  //   }

  //   .mat-form-field {
  //     border: 1.5px solid var(--m3-sys-error);
  //   }
  // }

  // @include media-breakpoint-down(md) {

  //   .mat-chip-list-wrapper input.mat-input-element,
  //   .mat-chip-list-wrapper .mat-standard-chip {
  //     margin: 4px;
  //   }
  // }

  .mat-chip.mat-standard-chip {
    background-color: var(--m3-sys-secondary-container);
    color: var(--m3-sys-on-secondary-container);
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    color: var(--m3-sys-on-secondary-container);
    opacity: unset !important;
  }

  input::placeholder {
    color: var(--m3-sys-on-syrface);
  }
}

.input-field {
  background: var(--m3-sys-surface);
  color: var(--m3-sys-on-surface);
  border: 1px solid var(--m3-sys-outline);
  padding: 8px 6px;
  border-radius: 5px;

  &:disabled {
    background: var(--m3-state-layers-on-surface-variant-opacity-12);
  }
}

.mat-select-placeholder {
  color: var(--m3-sys-outline);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: var(--m3-sys-outline) !important;
}

.field-space-md {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 1em !important;
  }
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(4%) !important;
}

.sales-order__discount-panel {
  .mat-custom .mat-mdc-form-field-has-label .mat-mdc-input-element {
    margin-top: 0 !important;
  }

  .mat-custom .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 10px 0;
  }

  .input-group__toggle .input-group__toggle--btn {
    display: flex;
    align-items: center;
    height: unset;
    top: calc(100% - 2.875rem);

  }
}

[dir=rtl] {
  .input-group__toggle .input-group__toggle--btn {

    top: calc(100% - 48px);

  }
}

.mat-list-item-content ngb-highlight {
  word-break: break-word;
  white-space: normal;
}

.md-drppicker td.active {
  background-color: var(--m3-sys-primary) !important;
  color: var(--m3-sys-on-primary) !important;
}

.dialog__height .dialog-datepicker .md-drppicker {
  right: auto !important;

  .filter__dropdown {
    .mat-form-field-appearance-fill .mat-form-field-flex {
      background-color: var(--color-surface-2);
    }
  }
}


.filter__dropdown {
  &--view {
    position: relative;
    max-width: 175px;

    .mdc-button__label {
      justify-content: space-between;
    }

    &--container {
      position: relative;
      z-index: 1000;
    }

    &--popup {
      padding: 1rem;
      position: absolute;
      bottom: -80px;
      left: 0;
      z-index: 1;
      padding: var(--space-base);
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      transition: all 0.15s ease-in-out;
      background: var(--white);

      .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
      .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
        background: transparent;
      }

      .ng-submitted .ng-invalid {
        .mat-form-field-flex {
          border: 2px solid var(--m3-sys-error);
        }
      }
    }

    &--submenu {
      // position: relative;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: var(--color-surface-2);
    border-radius: var(--border-radius-soft);
    padding: .75em;
    display: flex;
    align-items: center;
    margin: auto;
    height: 40px;
  }

  .mat-form-field-label-wrapper {
    display: none;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: var(--space-none);
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before {
    display: none;
  }

  .mat-form-field.mat-focused .mat-form-field-ripple {
    display: none;
  }

  .mat-form-field-appearance-fill:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
    display: none;
  }

  .mat-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding: var(--space-none);
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
    width: 125px;
  }

  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}

.filter-menu {
  margin-top: var(--space-s);

  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    padding-bottom: var(--space-none);
    margin-bottom: var(--space-none);
    margin-top: var(--space-none);
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field {
    margin-bottom: var(--space-none);
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 40px;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    min-height: auto;
  }

  .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: var(--space-none);
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0.75em;
    margin-top: var(--space-none);
    height: 40px;
  }
}

body {
  .filter__dropdown-option {
    min-width: 260px !important;
    margin-top: var(--space-xxl);

    .mat-option {
      padding: var(--space-none);
    }

    .mat-option-text {
      padding: var(--space-none) var(--space-m);
    }

    .dropdown-option-action {
      cursor: pointer !important;

      &:hover {
        background: var(--m3-sys-secondary-container) !important;
      }
    }
  }
}


.filter-view-input {

  &::placeholder {
    color: var(--m3-sys-on-surface);
  }

  &--container {

    .mat-form-field-flex {
      border: 2px solid transparent;
    }

    &--focus {
      .mat-form-field-flex {
        border: 2px solid var(--m3-sys-primary);
      }
    }

  }

  // border: 2px solid transparent;
  line-height: 22px;

  &:focus {
    // border: 2px solid var(--m3-sys-primary);
  }

}

.mat-select-disabled .mat-select-value {
  color: var(--m3-sys-outline) !important
}

.overlay-no-scroll .cdk-overlay-connected-position-bounding-box {
  pointer-events: auto;
}

body .m3-date-range {
  .mat-datepicker-content {
    box-shadow: none;
  }

  .mat-calendar-table-header-divider {
    display: none !important;
  }

  .mat-calendar-table-header {
    position: relative;
  }

  .mat-calendar-header {
    border-bottom: 1px solid var(--m3-sys-outline-variant);
  }

  .mat-calendar-content {
    margin-top: var(--space-base);
  }

  .mat-calendar-body-in-range::before {
    background-color: var(--m3-sys-secondary-container);
  }

  .mat-calendar-table-header th {
    font-size: 12px;
  }
}

body {

  .mat-datepicker-actions {
    border-top: 1px solid var(--m3-sys-outline-variant);
    padding: 8px 12px 12px;
  }

  .mat-raised-button:not(.mat-raised-button.mat-button-disabled, .mat-warn) {
    background-color: transparent;
    box-shadow: none;
    padding: 0 12px;
    border-radius: 0;

    &:hover {
      box-shadow: none;
    }
  }


  .mat-datepicker-content .mat-button {
    color: var(--m3-sys-primary);
    border-radius: 0;
  }
}

.dark-theme {
  .md-drppicker th.available.prev::before {
    content: url('../../assets/images/chevron-left-dark.svg');
  }

  .md-drppicker th.available.next::after {
    content: url('../../assets/images/chevron-right-dark.svg');
  }
}

.search-bar.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 28px 0 0 28px;
  background-color: var(--color-surface-3);
  border: 0;
}

.search-bar.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 28px 28px 0;
  background-color: var(--color-surface-3);
  border: 0;
}

.search-bar.mat-form-field-appearance-outline .mat-form-field-outline-gap {
  background-color: var(--color-surface-3);
  border: 0;
}

.search-bar.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  margin-inline-start: var(--space-base);
}

.search-bar.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  padding-bottom: var(--space-none);
  margin-bottom: var(--space-none);
}

.search-bar.mat-form-field-appearance-outline {
  margin-bottom: var(--space-none);
}

.search-bar.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: var(--space-base);
}

.search-bar--rounded.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: var(--space-s) var(--space-base);
}

.search-bar--unbound.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0;
}

.search-bar--unbound.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0;
}

body {

  .mat-form-field-prefix .mat-icon-button .mat-icon,
  .mat-form-field-suffix .mat-icon-button .mat-icon {
    line-height: 1.5rem;
  }

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: var(--m3-sys-on-surface);
  }

  .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true][disabled]~.mdc-checkbox__background {
    background-color: var(--m3-sys-on-surface);
  }

  .mat-mdc-checkbox-disabled {
    opacity: .38;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-radio-button .mdc-radio--disabled+label {
    color: var(--m3-sys-on-surface);
  }

  .mat-mdc-radio-button .mdc-radio [aria-disabled=true] .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--m3-sys-on-surface);
  }

  .mat-mdc-radio-button .mdc-radio [aria-disabled=true] .mdc-radio__native-control+.mdc-radio__background .mdc-radio__inner-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled+.mdc-radio__background .mdc-radio__inner-circle {
    background-color: #00000061;
  }
}

.search-bar--show-result.mat-form-field-appearance-outline .mat-form-field-outline {
  border-bottom: 1px solid var(--m3-sys-outline);
}

.search-bar--show-result.search-bar--rounded.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 28px 0 0 0;
  background-color: var(--color-surface-3);
  border: 0;
}

.search-bar--show-result.search-bar--rounded.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 28px 0 0;
  background-color: var(--color-surface-3);
  border: 0;
}

.mat-form-field-invalid {
  .mat-icon {
    color: var(--m3-sys-error);
  }
}

body {

  // checkbox
  .mdc-checkbox__background {
    width: 16px;
    height: 16px;
  }

  .mdc-checkbox {
    flex: unset;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: var(--m3-sys-on-surface);
  }

  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }



  .mat-primary {
    .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
      background-color: var(--m3-sys-primary);
    }

  }

  .mdc-checkbox__checkmark-path {
    stroke: var(--m3-sys-surface);
  }

  .mat-warn {
    .mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
      background-color: var(--m3-sys-error);
    }
  }

  .mat-warn.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--m3-sys-error) !important;
      border-color: var(--m3-sys-error) !important;
    }
  }

  .mat-warn {

    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      border-color: var(--m3-sys-error);
    }
  }

  .mat-primary {

    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      border-color: var(--m3-sys-primary);
    }
  }

  .mat-warn .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-warn .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-warn .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-error);
    border-color: var(--m3-sys-error);
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
    background-color: var(--m3-sys-primary);
    border-color: var(--m3-sys-primary);
  }

  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-primary);
  }

  .mat-warn .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mat-warn .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
  .mat-warn .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    background-color: var(--m3-sys-error);
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
    color: var(--m3-sys-surface);
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--m3-sys-on-surface);
  }

  .mat-mdc-option {
    color: var(--m3-sys-on-surface);
  }

  .mat-mdc-select {
    color: var(--m3-sys-on-surface);
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: var(--m3-sys-primary);
  }

  div.mat-mdc-select-panel {
    background-color: var(--m3-sys-background);
    padding: 0;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: var(--m3-sys-secondary-container);

    .mdc-list-item__primary-text {
      color: var(--m3-sys-primary);
    }

    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
      color: var(--m3-sys-primary);
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: var(--m3-sys-outline);
  }

  // radio button
  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--m3-sys-on-surface-variant);
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--m3-sys-primary);
  }

  .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--m3-sys-primary);
  }

  .switch-check .mdc-switch__icons svg {
    display: block;
  }

  .switch-check .mdc-switch__icons {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin: auto;
    fill: none;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
    fill: none;
  }

  .switch-check .mdc-switch__icon--off {
    background-image: url(/assets/images/close.svg);
    fill: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .switch-check .mdc-switch--unselected .mdc-switch__handle {
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    left: 6px;
  }

  .switch-check .mdc-switch--checked .mdc-switch__icon--on {
    display: block;
    background-image: url(/assets/images/check.svg);
    fill: none !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .switch-check--dark .mdc-switch--checked .mdc-switch__icon--on {
    background-image: url("/assets/images/check-dark.svg");
    fill: none !important;
    width: 14px;
    height: 14px;
  }

  .mat-mdc-slide-toggle .mdc-switch--disabled+label {
    color: var(--m3-sys-on-surface);
  }
}

body {
  .search-bar {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background-color: var(--color-surface-3);
      border: 0;
    }

    .mdc-notched-outline__leading {
      display: none;
    }

    &--rounded {
      .mdc-notched-outline__notch {
        border-radius: 28px 0 0 28px;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 28px 28px 0 !important;
      }

    }

    &--unbound {
      min-height: 75px;

      .mat-mdc-form-field-flex {
        align-items: center;
        margin: auto;
      }

      &:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
        transform: var(--mat-mdc-form-field-label-transform,
            translateY(0%) translateX(calc(1 * (28px + var(--mat-mdc-form-field-label-offset-x, 0px))))) !important;
      }
    }

    .mdc-floating-label {
      margin-inline-start: var(--space-l);
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .search-bar--rounded.search-bar--show-result {
    .mdc-notched-outline__notch {
      border-radius: 28px 0 0 0;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 28px 0 0 !important;
    }
  }

  .search-bar--show-result {
    .mdc-text-field--outlined {
      border-bottom: 1px solid var(--m3-sys-outline);
    }
  }

  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding: 0 .75em 0 .75em;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: var(--color);
  }

  .mat-mdc-form-field-icon-suffix .icon {
    fill: var(--color);
  }

  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: unset;
    --color: var(--m3-sys-on-surface);
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: var(--m3-sys-on-surface);
  }

  .mdc-form-field>label {
    color: var(--m3-sys-on-surface);
  }

  .mat-mdc-slide-toggle[disabled] {
    opacity: 0.38;
  }

  .mdc-switch:disabled .mdc-switch__track::before {
    background-color: var(--m3-sys-light-surface-variant);
  }
}


//fixes

.mat-mdc-form-field-error {
  color: var(--m3-sys-error) !important;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--m3-sys-error) !important;
}

body {

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: var(--color);
  }

  .mat-mdc-select-arrow svg {
    fill: var(--color);
  }

  .mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons) {
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
  }

  .mdc-switch__icons {
    margin: auto 0;
  }
}


.search-control {
  .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding-inline-start: 8px;
  }
}

body {
  .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
    color: var(--color);
  }

  .sales-order__head .field-space-none.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
}

.iti--allow-dropdown input.iti__tel-input,
.iti--allow-dropdown input.iti__tel-input[type=text],
.iti--allow-dropdown input.iti__tel-input[type=tel] {
  padding-right: var(--iti-input-padding) !important;
  padding-left: var(--iti-selected-country-arrow-padding) !important;
  margin-left: 0;
}

.cards-layout mat-option:hover .cards-layout__option-actions {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.cards-layout mat-option .cards-layout__option-content {
  align-items: center;
}




.mat-mdc-card .mat-mdc-form-field-flex {
  min-height: unset;
}



body {

  .mdc-checkbox,
  .mdc-checkbox .mdc-checkbox__native-control {
    width: 16px;
    height: 16px;
  }

  .mdc-checkbox {
    margin: auto;
    margin-inline-end: 8px;
  }

  .mdc-checkbox .mdc-checkbox__background {
    top: 0;
    left: 0;
  }
}


body {
  .pay-btn .mdc-button__label {
    display: block;
  }

  .mat-mdc-form-field-subscript-wrapper {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
  }

  .mat-mdc-form-field-subscript-wrapper {
    font-size: 75%;
    margin-top: .67em;
    top: calc(100% - 2.59em);
  }

  .mat-mdc-form-field {
    position: relative;
  }

  .mdc-text-field.mat-mdc-text-field-wrapper {
    margin-top: .25em;
    margin-bottom: 1.41875em;
    z-index: unset;
  }

}

body .mat-mdc-select {
  font: var(--font-regular) var(--font-size-base) / 1.425 var(--font-family);
}

body .mat-mdc-form-field {
  font: var(--font-regular) var(--font-size-base) / 1.425 var(--font-family);
}

body .mat-mdc-form-field-icon-prefix,
body .mat-mdc-form-field-icon-suffix {
  line-height: inherit;
}

body .mat-mdc-dialog-container .mdc-dialog__surface {
  transition: none !important;
}

body .mat-mdc-paginator-outer-container .mat-mdc-text-field-wrapper {
  margin-bottom: 0;
}


.mdc-data-table__row .mat-mdc-form-field:not(.mat-mdc-form-field-has-icon-suffix) .mat-mdc-text-field-wrapper {
  padding: 0 0.75em 0 0.75em !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.iti--inline-dropdown .iti__dropdown-content {
  min-width: 475px;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-flex {
  padding: 0;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: var(--color);
  margin-inline-start: 2px;
}

body .mdc-form-field>label {
  user-select: none;
  cursor: inherit;
  align-items: baseline;
  vertical-align: middle;
  display: inline-flex;
  white-space: nowrap;
  font-size: var(--font-size-s);
}

.auth {
  .mat-mdc-button.mat-primary {
    white-space: nowrap;
  }
}

body .filter-panel__form-wrapper {
  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    height: 46px;
    margin-bottom: var(--space-l) !important;
  }

  .mdc-text-field.mat-mdc-text-field-wrapper {
    height: 46px;
    margin-bottom: var(--space-l) !important;
  }

  .mdc-text-field.mat-mdc-text-field-wrapper {
    padding: 0 !important;
  }

  .mat-mdc-form-field-icon-suffix {
    line-height: unset;
    height: 24px;
    min-height: 24px;
    width: 24px;
  }

  .mdc-icon-button {
    line-height: unset;
    height: 24px;
    min-height: 24px;
    width: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .mdc-notched-outline__leading {
    display: none;
  }
}

body {
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: none !important;
    border-right: none !important;
  }
}




body {
  .warn-msg {
    background-color: var(--m3-sys-error) !important;
    color: var(--m3-sys-background) !important;
    width: 450px !important;

    icon {
      position: relative;
      top: 4px;
    }
  }
}

body .mat-expansion-panel {
  background: transparent;
}

[dir=rtl] body .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding: 0 0.75em 0 0.75em;
}

mat-error {
  >div {
    position: absolute;
    top: 0;
  }
}
﻿@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
  font-family: "hvtar";
  font-style: normal;
  font-weight: 600;
  src: local("hvtar-roman"),
  local("hvtar-roman"),
  url("#{$font-path}/hvtar-roman.woff") format("woff");
}

@font-face {
  font-family: "hvtar";
  font-style: normal;
  font-weight: 700;
  src: local("hvtar-bold"),
  local("hvtar-bold"),
  url("#{$font-path}/hvtar-bold.woff") format("woff");
}
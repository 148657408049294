// old theme

.typo-headline {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 2rem;
  margin: -0.5rem 0 1rem -0.06em;

}

.typo-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 2rem;
  margin: -0.5rem 0 1rem -0.05em;
}

.typo-subheading1 {
  -webkit-font-smoothing: antialiased;
  font-size: 0.938rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  margin: -0.313rem 0 0.813rem -0.06em;
}

.typo-body1 {
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.25rem;

  &--margin {
    margin: 0 0 0.75rem 0;
  }
}

.typo-body2 {
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  margin: -.25rem 0 .75rem;
}


.typo-color {
  &--primary {
    color: var(--m3-sys-primary) !important;
  }

  &--warn {
    color: var(--m3-sys-error) !important;
  }
}

// new theme
$font-weights: (
  "light": 300,
  "regular": 400,
  "medium": 500,
  "semi-bold": 600,
  "bold": 700,
);

:root {
  --typo-display-large: 57px;
  --typo-display-medium: 52px;
  --typo-display-small: 36px;
  --typo-headline-large: 32px;
  --typo-headline-medium: 28px;
  --typo-headline-small: 24px;
  --typo-title-large: 22px;
  --typo-title-medium: 16px;
  --typo-title-small: 14px;
  --typo-label-large: 14px;
  --typo-label-medium: 12px;
  --typo-label-small: 11px;
  --typo-body-large: 16px;
  --typo-body-medium: 14px;
  --typo-body-small: 12px;

  @each $weight,
  $value in $font-weights {
    --font-#{$weight}: #{$value};
  }
}

@each $weight,
$value in $font-weights {
  .font-#{$weight} {
    font-weight: var(--font-#{$weight}) !important;
  }
}

@mixin typo($name, $font-family: var(--font-family), $line-height: normal,
            $size, $tracking: 'normal', $weight: var(--font-regular), $transform: initial) {
  #{$name} {
    font-family: #{$font-family} !important;
    line-height: #{$line-height} !important;
    font-size: #{$size} !important;
    font-weight: #{$weight} !important;
    text-transform: #{$transform} !important;
    letter-spacing: #{$tracking} !important;
    margin: 0;
  }
}

@include typo(".typo-display-large", var(--font-family), 64px, var(--typo-display-large), -0.25px, var(--font-regular));
@include typo(".typo-display-medium", var(--font-family), 52px, var(--typo-display-medium), 0, var(--font-regular));
@include typo(".typo-display-small", var(--font-family), 44px, var(--typo-display-small), 0, var(--font-regular), none);
@include typo(".typo-headline-large", var(--font-family), 40px, var(--typo-headline-large), 0, var(--font-regular));
@include typo(".typo-headline-medium", var(--font-family), 36px, var(--typo-headline-medium), 0, var(--font-regular));
@include typo(".typo-headline-small", var(--font-family), 32px, var(--typo-headline-small), 0, var(--font-regular));
@include typo(".typo-title-large", var(--font-family), 28px, var(--typo-title-large), 0, var(--font-regular));
@include typo(".typo-title-medium", var(--font-family), 24px, var(--typo-title-medium), 0.1px, var(--font-medium));
@include typo(".typo-title-small", var(--font-family), 20px, var(--typo-title-small), 0.1px, var(--font-regular));
@include typo(".typo-label-large", var(--font-family), 20px, var(--typo-label-large), 0.1px, var(--font-medium));
@include typo(".typo-label-medium", var(--font-family), 16px, var(--typo-label-medium), 0.5px, var(--font-regular));
@include typo(".typo-label-small", var(--font-family), 16px, var(--typo-label-small), 0.5px, var(--font-regular));
@include typo(".typo-body-large", var(--font-family), 24px, var(--typo-body-large), 0.5px, var(--font-regular));
@include typo(".typo-body-medium", var(--font-family), 20px, var(--typo-body-medium), 0.25px, var(--font-regular));
@include typo(".typo-body-small", var(--font-family), 16px, var(--typo-body-small), 0.4px, var(--font-regular));

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-no-underline {
  text-decoration: none !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center !important;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-justify {
  text-align: justify !important;
}

.text-success{
  color: #28a745!important;
  .mat-icon{
     color: #28a745!important;
  }
}
.text-danger{
  color: var(--m3-sys-error) !important;
}
.mat-h3, .mat-subheading-2, .mat-typography .mat-h3, .mat-typography .mat-subheading-2, .mat-typography h3 {
  font: var(--font-regular) var(--typo-title-large) / 28px var(--font-family);
  letter-spacing: normal;
  margin: 0 0 1rem;
}

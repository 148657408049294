.loading {
  position: fixed;
  top: -1px;
  left: 0;
  z-index: 999;
  width: 100%;

  .mat-progress-bar-buffer {
    background-color: transparent;
  }

  .mat-progress-bar-background {
    fill: transparent;
  }
}

.callback-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-dots {
  clip-path: inset(0 1ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

[dir="rtl"] .loading-dots {
  clip-path: inset(0 0 0 1ch);
  animation: r 1s steps(4) infinite;
}

@keyframes r {
  to {
    clip-path: inset(0 0 0 -1ch);
  }
}

.mat-primary.mdc-button .mat-mdc-progress-spinner circle {
  stroke: var(--m3-white);
}
body {

  .bottom-nav-bar.mat-toolbar-row,
  .bottom-nav-bar.mat-toolbar-single-row {
    background-color: var(--color-surface-2);
    height: 80px;
  }

  .nav-bars {
    background-color: var(--color-surface-2);
    height: auto;

    .nav-bars__link {
      padding: 15px 0;
      height: auto;
    }
  }

  .nav-bars__link--badge {
    position: relative;

    .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: 10px;
      top: 0px;
    }
  }

  .nav-bars__icon {
    fill: var(--m3-sys-on-surface);
  }
}

body {
  .top-bar {

    .mat-mdc-button:not(:disabled) {
      padding: 0 0.75rem;
    }
  }
}